export const toMilitaryTimeStamp = (value: number) =>
  value.toString().padStart(2, "0");

export const renderSelectOptions = (items: number[]) => {
  return items.map((item: number) => {
    return {
      key: item,
      value: item,
      text: toMilitaryTimeStamp(item),
    };
  });
};

export const generateTimeUnits = (limit: number) => {
  return Array.from({ length: limit }, (_, i) => i);
};
