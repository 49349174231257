import config from "../../../config";
import getFetch from "../../getFetch";

const SwedBank = {
  getUserAgreements: function () {
    return getFetch({
      url: `${config.api_url}/payment/v1/swedbank/agreement`,
      type: "get",
    });
  },

  cancelOrder: function ({ ref }) {
    return getFetch({
      url: `${config.api_url}/payment/v1/swedbank/order/cancel/${ref}`,
      type: "put",
    });
  },

  creditCardVerificationInit: function (objectToSend) {
    return getFetch({
      url: `${config.api_url}/payment/v1/swedbank/verification/init?orderType=account_verification`,
      type: "post",
      params: objectToSend,
    });
  },

  creditCardVerificationCheck: function (authRef) {
    return getFetch({
      url: `${config.api_url}/payment/v1/swedbank/verification/${authRef}`,
      type: "put",
    });
  },

  successfulPayment: function ({ ref }) {
    return getFetch({
      url: `${config.api_url}/payment/v1/swedbank/payment/${ref}`,
      type: "put",
    });
  },

  initBank: function (objectToSend) {
    return getFetch({
      url: `${config.api_url}/payment/v1/swedbank/pay/init`,
      type: "post",
      params: objectToSend,
    });
  },

  quickCheckout: function (objectToSend) {
    return getFetch({
      url: `${config.api_url}/payment/v1/swedbank/agreement/pay`,
      type: "post",
      params: objectToSend,
    });
  },
};

export default SwedBank;
