import { Component } from "react";
import { connect } from "react-redux";

import { Icon, Segment, Sidebar, Loader, Input } from "semantic-ui-react";
import {
  PageToolbar,
  PageToolbarButtonsPane,
} from "../../../components/PageToolbar";
import Breadcrumbs from "../../../components/shared/breadcrumbs/Breadcrumbs";

import { toast } from "react-toastify";

import SortablePortList from "./SortablePortList";

// To toggle the modify Sliding menu
import { toggleSlidingMenu } from "../../../actions/toggleSlidingMenu";

import { withTranslation } from "react-i18next";
import { confirmbox_open } from "../../../components/confirmbox/actions";
import { KnowledgeBaseButton } from "../../../components/knowledgebase/KnowledgeBaseButton";

import { showViewMore, hideViewMore, deleteMultiplePorts } from "./actions";
import { toggleHiddenRegion, showMoreResources } from "../../../actions/shared";
import LoadMoreSensor from "../../../components/shared/LoadMoreSensor";

import { canLoadMore } from "../../../app_shared_functions";
import {
  addSubscription,
  removeSubscription,
} from "../../../actions/connectivity";
import { checkUserCRUDAccess } from "../../../shared-functions/authenticate";
import { debounceInput } from "../../../shared-functions/environment";
import CircularButton from "../../../components/shared/circularbutton/CircularButton";
import { getButtonCountPopup } from "../../../components/shared/circularbutton/util";
import GridContext from "../../../components/shared/grid-bits/context/GridContext";
import { valuesOf } from "../../../shared-functions/objects";
class PortList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filteringText: "",
    };
  }

  // Oh how I wish I could use a one-liner hook here :(
  componentDidMount() {
    this.props.addSubscription("PORTS_LIST");
  }
  componentWillUnmount() {
    this.props.removeSubscription("PORTS_LIST");
  }

  checkAndLoadMore = (isVisible) => {
    if (
      isVisible &&
      canLoadMore(
        this.props.ports.PORTS_LIST,
        this.props.currentPage,
        this.props.closedRegions,
      )
    ) {
      this.setState({ loading_more: true });
      this.props.showMoreResources();
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentPage < this.props.currentPage) {
      this.setState({ loading_more: false });
    }
  }

  toggleDeleteDialog(selectedPorts) {
    if (!selectedPorts.length) {
      toast.warn("No ports are selected for delete.");
    } else {
      const resources = selectedPorts.map(
        (x) => this.props.ports.PORTS_LIST[x],
      );
      this.props.confirmbox_open({
        entity: "port",
        operation: "delete",
        resources: resources,
        onConfirm: deleteMultiplePorts,
      });
    }
  }

  onFilterChange = debounceInput((text) =>
    this.setState({ filteringText: text }),
  );

  render() {
    const { t, selectedPorts } = this.props;
    const hasCRUDAccess = checkUserCRUDAccess(this.props.privileges);

    return (
      <div>
        <Sidebar.Pushable as={Segment}>
          <Sidebar.Pusher>
            <PageToolbar>
              <Breadcrumbs breadcrumbs={this.props.breadcrumbs} />
              <KnowledgeBaseButton />

              {hasCRUDAccess && (
                <PageToolbarButtonsPane>
                  <button
                    className="button button--transparent"
                    onClick={this.props.createPort}
                  >
                    {t(`ports.actions.add`)}
                    <Icon name="plus circle" />
                  </button>
                </PageToolbarButtonsPane>
              )}
            </PageToolbar>
            <div className="page-content" key="content">
              {/* Multi-select actions*/}
              <div className="top-section">
                {hasCRUDAccess && (
                  <div className="margin-bottom">
                    <CircularButton
                      onClick={() => this.toggleDeleteDialog(selectedPorts)}
                      className={`button button--circular margin-right-half `}
                      icon="trash"
                      count={selectedPorts.length}
                      popupContent={`${t(
                        `ports.actions.delete`,
                      )} ${getButtonCountPopup(selectedPorts.length, "port")}`}
                    />
                  </div>
                )}
                <div className="pos_right">
                  <div className="ui input input-white">
                    <Input
                      minLength={2}
                      onChange={(e) => this.onFilterChange(e.target.value)}
                      placeholder="Enter filter text..."
                    />
                  </div>
                </div>
              </div>
              <GridContext.Provider
                value={{
                  filteringText: this.state.filteringText,
                  resourceType: "ports",
                  hasCRUDAccess,
                }}
              >
                <SortablePortList />
              </GridContext.Provider>
              <LoadMoreSensor
                checkAndLoadMore={this.checkAndLoadMore}
                loading_more={this.state?.loading_more}
              />
            </div>
            {this.props.zonesLeft ? (
              <Loader
                size="tiny"
                active
                className="loading-fixed"
              >{`${this.props.zonesLeft} region(s) loading...`}</Loader>
            ) : (
              ""
            )}
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </div>
    );
  }
}

const reduceAttachedDevices = (ports) => {
  const initialValue = {
    Ports: ports.length,
  };

  let notAttached = 0;

  const attachedDevices = ports.reduce((acc, port) => {
    if (!port.device_type) {
      notAttached++;
    } else {
      const key = `Attached to ${port.device_type}`;
      acc[key] = (acc[key] ?? 0) + 1;
    }

    return acc;
  }, initialValue);

  if (notAttached > 0) {
    attachedDevices["Not Attached"] = notAttached;
  }

  return attachedDevices;
};

const mapStateToProps = (state) => ({
  ports: state.ports,
  currentPage: state.ports.CURRENT_PAGE,
  sidebar: state.sidebar,
  zonesLeft: state.ports.PORTS_LIST_LOADING_ZONES_LEFT,
  selectedPorts: state.ports.PORTS_LIST_SELECTED,

  closedRegions: state.ports.HIDDEN_REGIONS,
  privileges: state.login.userlogin.privileges,

  breadcrumbs: [
    {
      title: "menu.networks.networking",
    },
    {
      title: "menu.networks.networks",
      pathname: "/networking/networks",
    },
    {
      title: "menu.networks.ports",
      popup: reduceAttachedDevices(valuesOf(state.ports.PORTS_LIST)),
    },
  ],
});

const mapDispatchToProps = (dispatch) => ({
  modifyPort: (port) => dispatch(toggleSlidingMenu("modify", "Port", port)),
  createPort: () => dispatch(toggleSlidingMenu("create", "Port")),
  modifyServer: (server) =>
    dispatch(toggleSlidingMenu("modify", "Server", server)),
  modifyNetwork: (network) =>
    dispatch(toggleSlidingMenu("modify", "Network", network)),
  confirmbox_open: (options) => dispatch(confirmbox_open(options)),
  showViewMore: (name) => dispatch(showViewMore(name)),
  hideViewMore: (name) => dispatch(hideViewMore(name)),
  toggleHiddenRegion: (region) => dispatch(toggleHiddenRegion("port", region)),
  showMoreResources: () => dispatch(showMoreResources("port")),
  connectFloatingIp: (port) =>
    dispatch(toggleSlidingMenu("create", "Floating IP", port)),
  removeSubscription: (name) => dispatch(removeSubscription(name)),
  addSubscription: (name) => dispatch(addSubscription(name)),
  dispatch,
});

PortList = withTranslation()(PortList);
PortList = connect(mapStateToProps, mapDispatchToProps)(PortList);

export default PortList;
