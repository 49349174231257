import React, { useState, useEffect, useCallback } from "react";
import FetchAPI from "../../../../api/FetchAPI";
import { defaultValues } from "../../../../app_constants";
import { Icon } from "semantic-ui-react";
import TableWrapper from "../../../../components/shared/react-table/TableWrapper";
import { createTableHeaderObject } from "../../../../app_shared_functions";
import IDNameQuickView from "../../../../components/shared/quickview/extensions/IDNameQuickView";

const MembersList = ({ pool, triggerHeightChange }) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  const getHeight = useCallback(() => {
    const el = document.getElementById(`pool-id-${pool.id}`);
    const height = el?.getBoundingClientRect()?.height;
    triggerHeightChange(pool.id, height || 0);
  }, [pool.id, triggerHeightChange]);

  useEffect(() => {
    FetchAPI.LoadBalancer.Pools.Members.getList(pool)
      .then((res) => setData(mappedData(res.data)))
      .catch((error) => setData(null))
      .finally(() => {
        setLoading(false);
        getHeight();
      });
  }, [pool, getHeight]);

  const columns = createTableHeaderObject(
    "Members",
    defaultValues.members.columns,
  );

  const mappedData = (data) =>
    data.map((x) => ({
      name: x.name || "- No Name -",
      id: <IDNameQuickView id={x.id} name={x.name} entityType={"Member"} />,
      address: x.address,
      operating_status: x.operating_status,
      protocol_port: x.protocol_port,
      provisioning_status: x.provisioning_status,
    }));

  return (
    <div
      className="react-table--expanded-tr__content"
      onClick={(e) => e.stopPropagation()}
      id={`pool-id-${pool.id}`}
    >
      {loading && (
        <div className="one-liner float-left padding-left-20">
          <Icon name="spinner" loading className="margin-right-half" />
          Loading
        </div>
      )}
      {!loading && !data && (
        <div className="one-liner float-left padding-left-20">
          <Icon name="warning" className="margin-right-half" />
          Error getting members list
        </div>
      )}
      {!loading && data && !data.length ? (
        <div className="one-liner float-left padding-left-20">
          <Icon name="warning" className="margin-right-half" />
          No members found!
        </div>
      ) : null}
      {!loading && data && data.length ? (
        <TableWrapper data={data} columns={columns} />
      ) : null}
    </div>
  );
};

export default MembersList;
