import React, { useEffect, useState } from "react";
import { convertArrayToDropDownList } from "../../../../app_shared_functions";
import { Grid, Input, Loader, Ref, Select } from "semantic-ui-react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import useSubscribe from "../../../../custom-hooks/useSubscribe";

const PortsList = React.forwardRef(
  ({ region, selectedPort, errorClassName, handleChange }, ref) => {
    const [ports, setPorts] = useState();
    const allPorts = useSelector((state) => state.ports.PORTS_LIST);

    useSubscribe("PORTS_LIST");

    const updateList = (id) => {
      handleChange({
        name: "selectedPort",
        value: id,
      });
    };

    useEffect(() => {
      const list = Object.values(allPorts || {}).filter(
        (port) =>
          port.region.toLowerCase() === region.toLowerCase() &&
          !port["device_owner"],
      );

      setPorts(list);
      if (list?.length === 1)
        handleChange({
          name: "selectedPort",
          value: list[0].id,
        });
    }, [region, allPorts, handleChange]);

    if (ports === undefined)
      return (
        <div className="loader-wrapper">
          <Loader size="mini" active className="one-liner">
            Fetching Ports list...
          </Loader>
          <div className="separator width-100p padding-top-30 padding-bottom-30" />
        </div>
      );

    return (
      <>
        <Ref innerRef={ref}>
          <div
            className={`width-100p padding-top padding-bottom padding-left-half  padding-right-half ${errorClassName}`}
          >
            <Grid className={"width-100p margin-left-00 margin-right-00"}>
              <Grid.Row>
                <Grid.Column
                  textAlign="left"
                  width={8}
                  className={`flex vcenter`}
                >
                  <h5>Port</h5>
                </Grid.Column>
                <Grid.Column
                  textAlign="left"
                  width={8}
                  className={`flex vcenter`}
                >
                  {ports.length === 0 ? (
                    <Input
                      className="disabled"
                      value="No available ports found!"
                    />
                  ) : (
                    <Select
                      className="select-box full"
                      disabled={ports.length === 1}
                      value={selectedPort}
                      icon="chevron circle down"
                      placeholder="Select Port"
                      options={convertArrayToDropDownList(ports)}
                      onChange={(e, { value }) => updateList(value)}
                    />
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </Ref>
        <div className="separator width-100p padding-bottom-20" />
      </>
    );
  },
);

PortsList.propTypes = {
  region: PropTypes.string.isRequired,
  selectedPort: PropTypes.array.isRequired,
  externalIP: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  configDrive: PropTypes.oneOfType([
    PropTypes.oneOf([undefined]),
    PropTypes.bool,
  ]),
  handleChange: PropTypes.func.isRequired,
  errorClassName: PropTypes.string.isRequired,
};

export default PortsList;
