import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DropdownProps, CheckboxProps } from "semantic-ui-react";
import { toMilitaryTimeStamp } from "./helpers";
import { HandleChange, Maintenance } from "./types";

const useMaintenanceWindow = (
  maintenance: Maintenance,
  handleChange: HandleChange,
) => {
  const prevMaintenanceRef = useRef<Maintenance | null>(null);

  const [beginHour, setBeginHour] = useState(
    maintenance ? parseInt(maintenance.begin.slice(0, 2)) : 0,
  );
  const [beginMinute, setBeginMinute] = useState(
    maintenance ? parseInt(maintenance.begin.slice(2, 4)) : 0,
  );
  const [endHour, setEndHour] = useState(
    maintenance ? parseInt(maintenance.end.slice(0, 2)) : 1,
  );
  const [endMinute, setEndMinute] = useState(
    maintenance ? parseInt(maintenance.end.slice(2, 4)) : 0,
  );

  const validEndHours = useMemo(() => {
    const hours = [];
    for (let i = 1; i <= 6; i++) {
      hours.push((beginHour + i) % 24);
    }
    return hours;
  }, [beginHour]);

  const updateAndValidateTimeWindow = useCallback(
    (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
      const name: string = data.name;
      const value = data.value as number;

      const hourDiff = (24 + endHour - beginHour) % 24;

      switch (name) {
        case "start-hours":
          setBeginHour(value);
          setEndHour((value + hourDiff) % 24);
          break;

        case "start-minutes":
          if (hourDiff === 6 && value < endMinute) setEndMinute(value);
          setBeginMinute(value);
          break;

        case "end-hours":
          const newHourDiff = (24 + value - beginHour) % 24;
          setEndHour(value);
          if (newHourDiff === 6 && endMinute >= beginMinute)
            setEndMinute(beginMinute);
          break;

        case "end-minutes":
          if (!(hourDiff === 6 && value > beginMinute)) setEndMinute(value);
          break;

        default:
          break;
      }
    },
    [beginHour, beginMinute, endHour, endMinute],
  );

  const updateCheckbox = useCallback(
    (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
      const newMaintenance = { ...maintenance };
      const name = data.name;
      const value = data.checked || false;

      if (name === "machine-image-version") {
        newMaintenance.machineImageVersion = value;
      }
      if (name === "kubernetes-version") {
        newMaintenance.kubernetesVersion = value;
      }

      handleChange({ name: "maintenance", value: newMaintenance });
    },
    [handleChange, maintenance],
  );

  useEffect(() => {
    const newMaintenance = {
      ...maintenance,
      begin: `${toMilitaryTimeStamp(beginHour)}${toMilitaryTimeStamp(beginMinute)}00+0100`,
      end: `${toMilitaryTimeStamp(endHour)}${toMilitaryTimeStamp(endMinute)}00+0100`,
    };

    const prevMaintenance = prevMaintenanceRef?.current;
    if (
      prevMaintenance &&
      prevMaintenance?.begin === newMaintenance.begin &&
      prevMaintenance?.end === newMaintenance.end
    ) {
      return;
    }

    handleChange({ name: "maintenance", value: newMaintenance });
    prevMaintenanceRef.current = newMaintenance;
  }, [beginHour, beginMinute, endHour, endMinute, handleChange, maintenance]);

  useEffect(() => {
    if (!maintenance) {
      handleChange({
        name: "maintenance",
        value: {
          machineImageVersion: true,
          kubernetesVersion: true,
          begin: `000000+0100`,
          end: `010000+0100`,
        },
      });
    }
  }, [handleChange, maintenance]);

  return {
    timeWindow: {
      beginHour,
      beginMinute,
      endHour,
      endMinute,
    },
    validEndHours,
    updateAndValidateTimeWindow,
    updateCheckbox,
  };
};

export default useMaintenanceWindow;
