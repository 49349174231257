import React, { useMemo, useState } from "react";
import { useTable } from "react-table";
import { defaultValues } from "../../../app_constants";

function Table({ columns, data }) {
  // Here we find the first column that has primitive data type (string | null) skipping the objects and arrays, [we can pass components as data to the table]!
  // If such column is found, the default sort will be on that column
  const defaultSortColumn = columns.at(-1)?.columns.find((col) => {
    const { accessor } = col;
    return ["string", "number"].includes(typeof data?.[0]?.[accessor]);
  });

  const [currentColumn, setCurrentColumn] = useState(
    defaultSortColumn?.accessor ?? null,
  );
  const [sortAscending, setSortAscending] = useState(true);

  // Handle sorting logic
  const handleColumnSort = (columnId) => {
    if (currentColumn === columnId) {
      // Toggle sorting direction if the same column is clicked
      setSortAscending(!sortAscending);
    } else {
      // Set the new column and reset to ascending order
      setCurrentColumn(columnId);
      setSortAscending(true);
    }
  };

  const sortedData = useMemo(() => {
    if (!currentColumn) return data; // Return unsorted data if no column is selected

    // Sort the data based on the selected column and direction
    return [...data].sort((a, b) => {
      const aValue = a[currentColumn];
      const bValue = b[currentColumn];

      if (aValue === bValue) return 0;

      if (
        (typeof aValue === "string" && typeof bValue === "number") ||
        (typeof bValue === "string" && typeof aValue === "number")
      ) {
        if (sortAscending) {
          return String(aValue) > String(bValue) ? 1 : -1;
        } else {
          return String(aValue) < String(bValue) ? 1 : -1;
        }
      }
      if (sortAscending) {
        return aValue > bValue ? 1 : -1;
      } else {
        return aValue < bValue ? 1 : -1;
      }
    });
  }, [currentColumn, sortAscending, data]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: sortedData });

  // This function will only be triggered when a click on a row of a table, that is in a 'moredetail' view is clicked,

  // 'expanded', and the trigger function 'toggleExpand' will be passed as properties on each row
  //
  // For now, this functionality is only used in the 'PoolsList component' which is part of 'MoreData' in 'Loadbalancer' list
  const rowClick = (row, toggleExpand, trigger, e) => {
    if (
      trigger !== undefined &&
      !(
        e.target.tagName?.toLowerCase() === "button" ||
        e.target.parentNode.tagName?.toLowerCase() === "button"
      )
    ) {
      // The id property sent here can be a component, [like when QuickView is passed ]
      // in such cases we need to get the value of id instead of the component
      if (typeof row.original.id === "string") {
        toggleExpand(row.original.id);
      } else if (
        typeof row.original.id === "object" &&
        row.original.id !== null &&
        typeof row.original.id.props?.id === "string"
      ) {
        toggleExpand(row.original.id.props.id);
      }
    }
  };

  // Color object can be a part of each row sent to TabbleWrapper
  // __color__ : {frankfurt: "green", karlskrona: "red"}
  // where frankfurt and karlskrona are the columns that the color should be assigned to
  // in this case, a sample row can be something like
  // {
  //    __color__ : {frankfurt: "green", karlskrona: "red"},
  //    frankfurt : ....,
  //    karlskrona : ....,
  //    rest of columns
  // }
  const renderCellColor = (cell) =>
    cell.row?.original?.__color__?.[cell?.column?.id];

  const renderRowClassName = (cell) => {
    let className = "";

    const color = renderCellColor(cell);
    if (color) {
      className = `color-${color}`;
    }
    if (cell?.column?.className) {
      className = `${className} ${cell.column.className}`;
    }
    return className;
  };

  const renderRow_Simple = (row) => {
    return (
      <tr
        {...row.getRowProps()}
        className={row.original.className}
        onClick={(e) =>
          rowClick(row, row.original.toggleExpand, row.original.expanded, e)
        }
      >
        {row.cells.map((cell) => {
          return (
            <td {...cell.getCellProps()} className={renderRowClassName(cell)}>
              {cell.render("Cell")}
            </td>
          );
        })}
      </tr>
    );
  };

  const renderRow_Expanded = (row) => {
    return (
      <tr
        {...row.getRowProps()}
        style={{
          height: 30 + (row.original.height || 30) + "px",
        }}
        className={`react-table--expanded-tr ${row.original.className}`}
        onClick={(e) =>
          rowClick(row, row.original.toggleExpand, row.original.expanded, e)
        }
      >
        {row.cells.map((cell, i) => {
          return (
            <td {...cell.getCellProps()} className={renderRowClassName(cell)}>
              {cell.render("Cell")}
              {i === row.cells.length - 1
                ? row.original.ComponentToRender
                : null}
            </td>
          );
        })}
      </tr>
    );
  };

  const renderHeaderClassName = (column) => {
    let className = "";
    if (column.Header === defaultValues.react_table.hidden_header_column) {
      className = "display-none ";
    }
    if (column.className) {
      className = `${className} ${column.className} `;
    }
    if (column.width) {
      className = `${className} ${column.width} `;
    }
    return className;
  };

  return (
    <table className="react-table" {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                className={renderHeaderClassName(column)}
                {...column.getHeaderProps()}
                // Call handleColumnSort when a column is clicked
                onClick={() => handleColumnSort(column.id)}
              >
                {column.render("Header")}
                <span
                  className={
                    currentColumn === column.id
                      ? sortAscending
                        ? "sort--asc"
                        : "sort--desc"
                      : "sort--disabled"
                  }
                ></span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          // Based upon the 'expanded property' passed on in the row object,
          // We will render either a simple-row or an expanded-row
          return row.original.expanded
            ? renderRow_Expanded(row)
            : renderRow_Simple(row);
        })}
      </tbody>
    </table>
  );
}

function TableWrapper(props) {
  const columns = React.useMemo(() => props.columns, [props.columns]);

  const data = React.useMemo(() => props.data, [props.data]);

  return <Table columns={columns} data={data} />;
}

export default TableWrapper;
