import React from "react";
import FetchAPI from "../../../api/FetchAPI";
import FancyHeader from "../../../components/shared/FancyHeader";

import { connect } from "react-redux";
import { defaultValues } from "../../../app_constants";
import { createCluster } from "./actions";
import {
  Checkbox,
  Grid,
  Icon,
  Input,
  Loader,
  Popup,
  Ref,
  Select,
} from "semantic-ui-react";
import {
  get_FormItem_ClassName,
  get_FormRow_ClassName,
  getCurrentProjectID,
  getFullRegionName,
  handleScrollToItem,
  renderZonesForSelectBox,
  toastError,
} from "../../../app_shared_functions";
import { getSelectItemClassName } from "../../../shared-functions/string";

import Flavors from "../../servers/flavors/Flavors";
import { getQuotaExceedingResources } from "../../../shared-functions/quota";

class ClusterCreator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreating: false,
      zone: "",
      name: "",
      templates: [],
      masterFlavorStatus: true,
      master_count: defaultValues.cluster.master_count,
      node_count: defaultValues.cluster.node_count,
      discovery_url: "",
      dockerVolumeSize: 50,
    };
  }

  componentDidMount() {
    if (this.props.predefined_params) {
      this.getKeyPairList(this.props.predefined_params.region);
      this.getFlavorData({
        region: this.props.predefined_params.region.toLowerCase(),
        project_id: this.props.predefined_params.project_id,
        master: this.props.predefined_params.master_flavor_id,
        flavor: this.props.predefined_params.flavor_id,
      });
    }

    this.setState({
      areasList: renderZonesForSelectBox(
        this.props.projects,
        this.props.userDomains,
      ),
    });
  }

  getRequestedQuota = () => {
    const masterCount = Number(this.state.master_count) || 1;
    const nodeCount = Number(this.state.node_count) || 1;
    const dockerVolumeCount = this.state.dockerVolumeSize ? 1 : 0;
    const { ram, ram2, cores, cores2, volume, volume2 } = this.state;
    const maxVolume = Math.max(Number(volume) || 1, Number(volume2) || 1);
    const dockerVolumeSize = Number(this.state.dockerVolumeSize) || 0;

    return {
      compute: {
        num_servers: masterCount + nodeCount,
        ram_gb:
          (Number(ram) || 1) * masterCount + (Number(ram2) || 1) * nodeCount,
        cores:
          (Number(cores) || 1) * masterCount +
          (Number(cores2) || 1) * nodeCount,
      },
      block_storage: {
        num_volumes: masterCount + nodeCount + dockerVolumeCount,
        volume_gb:
          Number(maxVolume) * (masterCount + nodeCount) + dockerVolumeSize,
      },
    };
  };

  updateform = (name, data) => {
    switch (name) {
      case "zone":
        if (!this.state.zone || this.state.zone.value !== data.value) {
          this.setState({
            [name]: data,
            keypair: null,
            invalidForm: false,
            selectedTemplate: null,
          });
          this.getTemplatesList(data.value);
        }
        this.getRegionQuota(data.value);
        break;

      case "selectedTemplate":
        this.setState({
          [name]: data,
          invalidForm: false,
        });
        this.getFlavorData({
          region: this.state.zone.value.toLowerCase(),
          project_id: getCurrentProjectID(
            this.props.projects,
            this.state.zone.value.toLowerCase(),
          ),
          master: data.master_flavor_id,
          flavor: data.flavor_id,
        });
        break;

      case "keypair":
        this.setState({ [name]: data.value, invalidForm: false });
        break;

      case "dockerVolumeSize":
        this.setState({
          [name]: data.replace(/[^0-9]/g, ""),
          invalidForm: false,
        });
        break;

      case "master_count":
        if (Number.isInteger(Number(data)))
          this.setState({ [name]: data, invalidForm: false });
        break;

      case "node_count":
        if (Number.isInteger(Number(data)))
          this.setState({ [name]: data, invalidForm: false });
        break;

      case "name":
        if (/^[a-zA-Z][a-zA-Z0-9_.-]*$/.test(data) || data === "")
          this.setState({ [name]: data, invalidForm: false });
        break;

      default:
        this.setState({ [name]: data, invalidForm: false });
        break;
    }
  };

  updateCallbacks = {
    keypair: (_e, d) => this.updateform("keypair", d),
    dockerVolumeSize: (e) =>
      this.updateform("dockerVolumeSize", e.currentTarget.value),
    masterCount: (e) => this.updateform("master_count", e.currentTarget.value),
    nodeCount: (e) => this.updateform("node_count", e.currentTarget.value),
    name: (e) => this.updateform("name", e.currentTarget.value),
    zone: (_e, d) =>
      this.updateform(
        "zone",
        this.state.areasList.find((area) => area.value === d.value),
      ),
    selectedTemplate: (_e, d) =>
      this.updateform(
        "selectedTemplate",
        this.state.templates.find((t) => t.uuid === d.value),
      ),
    discoveryUrl: (e) =>
      this.updateform("discovery_url", e.currentTarget.value),
    masterFlavorStatus: () =>
      this.setState({ masterFlavorStatus: !this.state.masterFlavorStatus }),
  };

  innerRefCallbacks = {
    nameRef: (x) => (this.nameRef = x),
    zoneRef: (x) => (this.zoneRef = x),
    keypairRef: (x) => (this.keypairRef = x),
    templateRef: (x) => (this.templateRef = x),
    dockerVolumeSizeRef: (x) => (this.dockerVolumeSizeRef = x),
  };

  getRegionQuota = (region) => {
    FetchAPI.Usage.getQuota({
      region,
      project_id: getCurrentProjectID(this.props.projects, region),
    }).then((res) => {
      this.setState({ quota: res.data });
    });
  };

  getFlavorData = ({ region, project_id, master, flavor }) => {
    const requests = [
      FetchAPI.Compute.Flavors.show({
        region,
        project_id,
        flavor_id: master,
      }),
      FetchAPI.Compute.Flavors.show({
        region,
        project_id,
        flavor_id: flavor,
      }),
    ];

    Promise.all(requests).then((responses) => {
      this.setState({
        cores: responses[0].data.vcpus,
        ram: responses[0].data.ramGB,
        volume: responses[0].data.disk,
        cores2: responses[1].data.vcpus,
        ram2: responses[1].data.ramGB,
        volume2: responses[1].data.disk,
        gotFlavors: true,
        defaultFlavorData: {
          cores: responses[0].data.vcpus,
          ram: responses[0].data.ramGB,
          volume: responses[0].data.disk,
          cores2: responses[1].data.vcpus,
          ram2: responses[1].data.ramGB,
          volume2: responses[1].data.disk,
        },
      });
    });
  };

  getTemplatesList = (region) => {
    const project_id = getCurrentProjectID(this.props.projects, region);
    this.setState({ templatesLoading: true });

    FetchAPI.ContainerManagement.ClusterTemplates.getList({
      region,
      project_id,
    })
      .then((response) => {
        if (response && response.status === 200) {
          this.setState({
            templates: response.data, //list of searched snapshots, this will be changed by user search
            templatesLoading: false,
          });
        }
      })
      .catch((err) => {
        toastError(err, "Cluster templates load failed!");
        this.setState({
          templatesLoading: false,
        });
      })
      .finally(() => this.getKeyPairList(region));
  };

  getKeyPairList = (region) => {
    const project_id = getCurrentProjectID(this.props.projects, region);
    this.setState({ keypairsLoading: true });

    FetchAPI.Compute.KeyPairs.getList({
      region,
      project_id,
    })
      .then((response) => {
        if (response && response.status === 200) {
          this.setState({
            keypairs: response.data, //list of searched snapshots, this will be changed by user search
            keypairsLoading: false,
          });
        }
      })
      .catch((err) => {
        toastError(err, "Keypairs list load failed");
        this.setState({ keypairsLoading: false });
      });
  };

  create = () => {
    const region =
      (this.state.zone && this.state.zone.value.toLowerCase()) ||
      this.props.predefined_params.region.toLowerCase();
    let objectToSend = {
      name: this.state.name || this.props.predefined_params.name,
      template_id:
        (this.state.selectedTemplate && this.state.selectedTemplate.uuid) ||
        this.props.predefined_params.uuid,
      keypair: this.state.keypair || this.props.predefined_params.keypair_id,
    };
    if (this.state.master_count)
      objectToSend.master_count = Number(this.state.master_count) || 1;
    if (this.state.node_count)
      objectToSend.node_count = Number(this.state.node_count) || 1;
    if (this.state.discovery_url)
      objectToSend.discovery_url = this.state.discovery_url;
    if (this.state.dockerVolumeSize)
      objectToSend.dockerVolumeSize = Number(this.state.dockerVolumeSize);
    if (
      this.state.defaultFlavorData.cores !== this.state.cores ||
      this.state.defaultFlavorData.ram !== this.state.ram ||
      this.state.defaultFlavorData.volume !== this.state.volume ||
      this.state.defaultFlavorData.cores2 !== this.state.cores2 ||
      this.state.defaultFlavorData.ram2 !== this.state.ram2 ||
      this.state.defaultFlavorData.volume2 !== this.state.volume2
    )
      objectToSend = {
        ...objectToSend,
        cores: this.state.cores,
        ram: this.state.ram,
        volume: this.state.volume,
        cores2: this.state.cores2,
        ram2: this.state.ram2,
        volume2: this.state.volume2,
      };

    const currentProjectID = getCurrentProjectID(this.props.projects, region);

    this.setState({ isCreating: true });

    this.props
      .createCluster(region, currentProjectID, objectToSend)
      .then((response) => {
        this.setState({
          isCreating: false,
        });
        if (!this.props.createAnother) this.props.closeSlidingMenuLayer();
      })
      .catch((err) => {
        this.setState({
          isCreating: false,
        });
      });
  };

  updateMasterFlavors = ({ ram, core, volume }) => {
    this.setState({
      ram,
      cores: core,
      volume,
    });
    if (this.state.masterFlavorStatus) {
      this.updateFlavors({ ram, core, volume });
    }
  };

  updateFlavors = ({ ram, core, volume }) => {
    this.setState({
      ram2: ram,
      cores2: core,
      volume2: volume,
    });
  };

  check_required_fields = () => {
    let returnValue = null;
    if (!this.state.name) {
      returnValue = {
        text: "Please provide a name for your Magnum Cluster",
        ref: "nameRef",
      };
    } else if (
      (this.props.predefined_params && !this.props.predefined_params.region) ||
      (!this.props.predefined_params && !this.state.zone)
    ) {
      returnValue = {
        text: "Please choose a Region",
        ref: "zoneRef",
      };
    } else if (
      (this.props.predefined_params && !this.props.predefined_params.name) ||
      (!this.props.predefined_params && !this.state.selectedTemplate)
    ) {
      returnValue = {
        text: "Please choose a Template",
        ref: "templateRef",
      };
    } else if (!this.state.keypair) {
      returnValue = {
        text: "Please choose a Keypair",
        ref: "keypairRef",
      };
    } else if (
      this.state.dockerVolumeSize &&
      this.state.dockerVolumeSize <= 0
    ) {
      returnValue = {
        text: "Please choose value higher than 0 for Docker Volume Size or leave this field empty",
        ref: "dockerVolumeSizeRef",
      };
    } else {
      const requested = this.getRequestedQuota();
      const isExceeding = getQuotaExceedingResources(
        this.state?.quota?.available || {},
        requested,
      );
      if (isExceeding) {
        returnValue = {
          text: isExceeding,
          ref: "advancedRef",
        };
      }
    }

    if (returnValue && this.state.shake === true) {
      const element = this[returnValue?.ref]?.firstElementChild;
      if (element && element.tagName?.toLowerCase() === "input") {
        element.focus();
      }
      setTimeout(() => {
        this.setState({ shake: false });
      }, 1000);
    }
    return returnValue;
  };

  render() {
    const { domains } = this.props;
    const predefined_template = this.props.predefined_params;
    const {
      zone,
      templates,
      templatesLoading,
      keypairs,
      keypairsLoading,
      areasList,
      invalidForm,
    } = this.state;

    const form_status = this.check_required_fields();

    return (
      <div className={`creator-component-wrapper`}>
        <div className="">
          <FancyHeader title="Create a Magnum Cluster" knowledgeBase />
          <p></p>
          <Grid>
            {/* NAME ZONE */}
            <Grid.Row className="separator padding-top-30">
              <Grid.Column textAlign="left" width={8}>
                <h5>
                  Name
                  <Popup
                    trigger={
                      <Icon
                        name="warning circle"
                        color="grey"
                        size="small"
                        className="margin-left-10"
                      />
                    }
                  >
                    Name should begin with a letter and can contain alphabets,
                    digits, underscore, dot, dash [A-Za-z0-9_.-]
                  </Popup>
                </h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Ref innerRef={this.innerRefCallbacks.nameRef}>
                  <Input
                    value={this.state.name}
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "nameRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    onChange={this.updateCallbacks.name}
                  />
                </Ref>
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="margin-top-30 flex vcenter"
              >
                <h5>Region</h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="margin-top-30 "
              >
                {(predefined_template || areasList) && (
                  <Ref innerRef={this.innerRefCallbacks.zoneRef}>
                    <Select
                      icon="chevron circle down"
                      disabled={predefined_template ? true : false}
                      value={
                        predefined_template?.region || this.state.zone.value
                      }
                      className={get_FormItem_ClassName(
                        form_status,
                        invalidForm,
                        "zoneRef",
                        this.state.shake,
                        "error-form-item",
                      )}
                      placeholder="Choose Region"
                      options={
                        predefined_template && predefined_template.name
                          ? [
                              {
                                key: 1,
                                value: predefined_template.region,
                                text: getFullRegionName(
                                  domains,
                                  predefined_template.region,
                                ),
                                className: getSelectItemClassName(
                                  getFullRegionName(
                                    domains,
                                    predefined_template.region,
                                  ),
                                ),
                              },
                            ]
                          : areasList
                      }
                      onChange={this.updateCallbacks.zone}
                    />
                  </Ref>
                )}
              </Grid.Column>
            </Grid.Row>

            {(predefined_template || zone) && (
              <Grid.Row className="separator padding-top-30">
                <Grid.Column
                  textAlign="left"
                  width={8}
                  className="flex vcenter"
                >
                  <h5>Template</h5>
                </Grid.Column>
                <Grid.Column textAlign="left" width={8}>
                  {(predefined_template || templates.length > 0) &&
                    !templatesLoading && (
                      <Ref innerRef={this.innerRefCallbacks.templateRef}>
                        <Select
                          icon="chevron circle down"
                          disabled={predefined_template ? true : false}
                          value={
                            (predefined_template && predefined_template.name) ||
                            (this.state.selectedTemplate &&
                              this.state.selectedTemplate.uuid)
                          }
                          className={get_FormItem_ClassName(
                            form_status,
                            invalidForm,
                            "templateRef",
                            this.state.shake,
                            "error-form-item",
                          )}
                          placeholder="Choose Template"
                          options={
                            predefined_template && predefined_template.name
                              ? [
                                  {
                                    key: predefined_template.name,
                                    value: predefined_template.name,
                                    text: predefined_template.name,
                                    className: getSelectItemClassName(
                                      predefined_template.name,
                                    ),
                                  },
                                ]
                              : templates.map((t) => ({
                                  key: t.uuid,
                                  value: t.uuid,
                                  text: t.name,
                                  className: getSelectItemClassName(t.name),
                                }))
                          }
                          onChange={this.updateCallbacks.selectedTemplate}
                        />
                      </Ref>
                    )}
                  {!predefined_template &&
                    templates.length === 0 &&
                    templatesLoading !== undefined &&
                    !templatesLoading && (
                      <div
                        className={get_FormItem_ClassName(
                          form_status,
                          invalidForm,
                          "templateRef",
                          this.state.shake,
                          "error-form-group",
                        )}
                      >
                        No Templates available in current Region
                      </div>
                    )}
                  {templatesLoading && (
                    <div className="loader-wrapper">
                      <Loader size="mini" active className="one-liner">
                        Fetching Templates list...
                      </Loader>
                    </div>
                  )}
                </Grid.Column>
              </Grid.Row>
            )}

            {(predefined_template || zone) && (
              <Grid.Row className="separator padding-top-30">
                <Grid.Column
                  textAlign="left"
                  width={8}
                  className="flex vcenter"
                >
                  <h5>Keypair</h5>
                </Grid.Column>
                <Grid.Column textAlign="left" width={8}>
                  {keypairs && keypairs.length > 0 && !keypairsLoading && (
                    <Ref innerRef={this.innerRefCallbacks.keypairRef}>
                      <Select
                        icon="chevron circle down"
                        value={
                          (predefined_template &&
                            predefined_template.keypair_id) ||
                          this.state.keypair
                        }
                        className={get_FormItem_ClassName(
                          form_status,
                          invalidForm,
                          "keypairRef",
                          this.state.shake,
                          "error-form-item",
                        )}
                        placeholder="Choose Keypair"
                        options={keypairs.map((x, id) => ({
                          key: id,
                          text: x.keypair.name,
                          value: x.keypair.name,
                          className: getSelectItemClassName(x.keypair.name),
                        }))}
                        onChange={this.updateCallbacks.keypair}
                      />
                    </Ref>
                  )}
                  {keypairs &&
                    keypairs.length === 0 &&
                    keypairsLoading !== undefined &&
                    !keypairsLoading && (
                      <div
                        className={get_FormItem_ClassName(
                          form_status,
                          invalidForm,
                          "keypairRef",
                          this.state.shake,
                          "error-form-group",
                        )}
                      >
                        No Keypairs available in current Region
                      </div>
                    )}
                  {keypairsLoading && (
                    <div className="loader-wrapper">
                      <Loader size="mini" active className="one-liner">
                        Fetching keypairs list...
                      </Loader>
                    </div>
                  )}
                </Grid.Column>

                <Grid.Column
                  textAlign="left"
                  width={8}
                  className=" margin-top-30 flex vcenter"
                >
                  <h5>
                    Docker volume size
                    <Popup
                      trigger={
                        <Icon
                          name="warning circle"
                          color="grey"
                          size="small"
                          className="margin-left-10"
                        />
                      }
                    >
                      Docker Volume Size in GB - this value specifies the size
                      of an additional Cinder volume that is attached to each
                      node in the cluster specifically for Docker storage. This
                      volume is used by Docker to store container images, logs,
                      and other related data. Leave empty for no docker volume.
                    </Popup>
                  </h5>
                </Grid.Column>
                <Grid.Column
                  textAlign="left"
                  width={8}
                  className=" margin-top-30 flex vcenter"
                >
                  <Ref innerRef={this.innerRefCallbacks.dockerVolumeSizeRef}>
                    <Input
                      value={this.state.dockerVolumeSize}
                      maxLength="5"
                      className={get_FormItem_ClassName(
                        form_status,
                        invalidForm,
                        "dockerVolumeSizeRef",
                        this.state.shake,
                        "error-form-item",
                      )}
                      onChange={this.updateCallbacks.dockerVolumeSize}
                    />
                  </Ref>
                </Grid.Column>
              </Grid.Row>
            )}

            {/* ADVANCED OPTIONS */}
            <Grid.Row className="separator">
              <Grid.Column textAlign="left" width={16}>
                <h4
                  className={`${get_FormRow_ClassName(
                    form_status,
                    "advancedRef",
                    invalidForm,
                    this.state.shake,
                  )} padding-top flex cursor_pointer`}
                  onClick={() =>
                    this.setState({ advanced: !this.state.advanced })
                  }
                >
                  {this.state.advanced ? (
                    <Icon
                      className="advanced_icon"
                      name="chevron circle down"
                    ></Icon>
                  ) : (
                    <Icon
                      className="advanced_icon"
                      name="chevron circle right"
                    ></Icon>
                  )}
                  <span>Advanced Options</span>
                </h4>
              </Grid.Column>
              {this.state.advanced && (
                <React.Fragment>
                  {/* MASTER COUNT */}
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className=" margin-top-30 flex vcenter"
                  >
                    <h5>
                      Master count
                      <Popup
                        content="The number of servers that will serve as master for the bay/cluster. The default is 1. Set to more than 1 master to enable High Availability."
                        trigger={
                          <Icon
                            name="warning circle"
                            color="grey"
                            className="margin-left-10"
                          />
                        }
                      />
                    </h5>
                  </Grid.Column>
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className=" margin-top-30"
                  >
                    <Input
                      value={this.state.master_count}
                      placeholder="1"
                      maxLength="5"
                      className="select-box full"
                      onChange={this.updateCallbacks.masterCount}
                    />
                  </Grid.Column>

                  {/* NODE COUNT */}
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className=" margin-top-30 flex vcenter"
                  >
                    <h5>
                      Node count
                      <Popup
                        content="The number of servers that will serve as node in the bay/cluster."
                        trigger={
                          <Icon
                            name="warning circle"
                            color="grey"
                            className="margin-left-10"
                          />
                        }
                      />
                    </h5>
                  </Grid.Column>
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className=" margin-top-30"
                  >
                    <Input
                      value={this.state.node_count}
                      placeholder="1"
                      maxLength="5"
                      className="select-box full"
                      onChange={this.updateCallbacks.nodeCount}
                    />
                  </Grid.Column>

                  {predefined_template || this.state.selectedTemplate ? (
                    <React.Fragment>
                      {/* MASTER FLAVOR */}
                      <Grid.Column
                        textAlign="left"
                        width={16}
                        className="margin-top-30"
                      >
                        <h5 className="margin-bottom-half">
                          Master flavor
                          <Popup
                            content="The nova flavor specified to the master node for this baymodel/cluster template."
                            trigger={
                              <Icon
                                name="warning circle"
                                color="grey"
                                className="margin-left-10"
                              />
                            }
                          />
                        </h5>
                      </Grid.Column>

                      <Grid.Column
                        textAlign="left"
                        width={16}
                        className="margin-top-30"
                      >
                        <Flavors
                          minRam={1}
                          defaultRam={this?.state?.ram || 1}
                          defaultVolume={this?.state?.volume}
                          defaultCores={this?.state?.cores}
                          updateFlavors={this.updateMasterFlavors}
                        />
                      </Grid.Column>

                      <Grid.Column
                        textAlign="left"
                        width={16}
                        className="margin-top-10"
                      >
                        <Checkbox
                          toggle
                          checked={this.state.masterFlavorStatus}
                          label="Use the same flavor for all nodes"
                          onChange={this.updateCallbacks.masterFlavorStatus}
                        />
                      </Grid.Column>

                      {/* FLAVOR */}
                      {!this.state.masterFlavorStatus && (
                        <React.Fragment>
                          <Grid.Column
                            textAlign="left"
                            width={16}
                            className="margin-top-30"
                          >
                            <h5 className="margin-bottom-half">
                              Node flavor
                              <Popup
                                content="The nova flavor for booting the node servers."
                                trigger={
                                  <Icon
                                    name="warning circle"
                                    color="grey"
                                    className="margin-left-10"
                                  />
                                }
                              />
                            </h5>
                          </Grid.Column>
                          <Grid.Column
                            textAlign="left"
                            width={16}
                            className="margin-top-30"
                          >
                            <Flavors
                              minRam={1}
                              defaultRam={this?.state?.ram2 || 1}
                              defaultVolume={this?.state?.volume2}
                              defaultCores={this?.state?.cores2}
                              updateFlavors={this.updateFlavors}
                            />
                          </Grid.Column>
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  ) : null}

                  <Grid.Column textAlign="left" width={16}></Grid.Column>

                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className=" margin-top-30 flex vcenter"
                  >
                    <h5>
                      Discovery url
                      <Popup
                        trigger={
                          <Icon
                            name="warning circle"
                            color="grey"
                            size="small"
                            className="margin-left-10"
                          />
                        }
                      >
                        The custom discovery url for node discovery. This is
                        used by the COE to discover the servers that have been
                        created to host the containers
                      </Popup>
                    </h5>
                  </Grid.Column>
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className=" margin-top-30 flex vcenter"
                  >
                    <Input
                      value={this.state.discovery_url}
                      className="select-box full"
                      onChange={this.updateCallbacks.discoveryUrl}
                    />
                  </Grid.Column>
                </React.Fragment>
              )}
            </Grid.Row>

            {/* CREATE BUTTONS */}
            <Grid.Row className=" padding-top-30">
              <Grid.Column textAlign="left" width={16}>
                {!form_status ? (
                  /* {(name) &&
                        (selectedTemplate || predefined_template) && 
                        (keypair || predefined_template)  }*/
                  this.state.isCreating ? (
                    <button className="float-right button button--green overflow-hidden button--icon__right ">
                      <Icon loading name="spinner" />
                      <span>Creating</span>
                    </button>
                  ) : (
                    <button
                      className="float-right button button--green"
                      onClick={() => this.create()}
                    >
                      <span>Create</span>
                    </button>
                  )
                ) : (
                  <Popup
                    trigger={
                      <button
                        className="float-right button button--green button--disabled button--icon__left"
                        onClick={() => {
                          this.setState({ invalidForm: true, shake: true });
                          handleScrollToItem(this[form_status.ref]);
                        }}
                      >
                        <Icon name="exclamation circle" />
                        <span>Create</span>
                      </button>
                    }
                  >
                    {form_status?.text}
                  </Popup>
                )}
                <Checkbox
                  className="simple-checkbox float-right margin-top-half"
                  label="Create Another "
                  checked={this.props.createAnother}
                  onChange={this.props.changeCreateAnother}
                />
                <button
                  className="button button--bordered"
                  onClick={() => this.props.closeSlidingMenuLayer()}
                >
                  <span>Back</span>
                </button>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}></Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    domains: state.domains.list,
    projects: state.projects,
    userDomains: state.usersettings?.selectedDomains || null,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createCluster: (rgn, pid, obj) => dispatch(createCluster(rgn, pid, obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClusterCreator);
