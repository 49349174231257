import React from "react";
import FancyHeader from "../../../components/shared/FancyHeader";

import { connect } from "react-redux";
import {
  Select,
  Grid,
  Input,
  Checkbox,
  Icon,
  Popup,
  Ref,
} from "semantic-ui-react";
import { createSubnet } from "./actions";
import {
  getFullRegionName,
  renderZonesForSelectBox,
  handleScrollToItem,
  get_FormItem_ClassName,
  getCurrentProjectID,
  toastError,
} from "../../../app_shared_functions";
import { getSelectItemClassName } from "../../../shared-functions/string";
import { defaultValues } from "../../../app_constants";
import Networks from "../../../components/shared/resources-list/Networks";
import IPv6SubnetSettings from "./IPv6SubnetSettings";

import FetchAPI from "../../../api/FetchAPI";

class SubnetCreator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreating: false,
      zone: "",
      name: "",
      network_id: "",
      ip_version: defaultValues.subnet.ip_version[0],
      cidr: "",
      ipv6SubnetSettings: null,
      ipv6SubnetSettingsFormChecker: null,
    };
  }

  updateform = (name, data) => {
    if (
      name === "zone" &&
      (!this.state.zone || this.state.zone.value !== data.value)
    ) {
      this.setState(
        {
          networks: null,
          network_id: null,
          invalidForm: false,
          cidr: `10.${data.key}.0.0/24`,
          [name]: data,
        },
        () => this.getNetworks(data.value),
      );
    } else {
      this.setState({ [name]: data, invalidForm: false });
    }
  };

  updateNetwork = (value) => {
    this.setState({
      network_id: value,
      invalidForm: false,
    });
  };

  updateIPv6SubnetSettings = (settings) => {
    this.setState({
      ipv6SubnetSettings: settings,
      invalidForm: false,
    });
  };

  createSubnet = () => {
    let region = this.state.zone.value;
    let objectToSend = {
      subnet: {
        name: this.state.name,
        network_id: this.state.network_id,
        ip_version: this.state.ip_version,
      },
    };

    if (this.state.ip_version === "4") {
      objectToSend["subnet"]["cidr"] =
        this.state.cidr ||
        (this.state.zone ? `10.${this.state.zone.key}.0.0/24` : "");
    } else {
      objectToSend["subnet"] = {
        ...objectToSend["subnet"],
        ...this.state.ipv6SubnetSettings,
      };
    }

    const currentProjectID = getCurrentProjectID(this.props.projects, region);
    this.setState({ isCreating: true });
    this.props
      .createSubnet(region, currentProjectID, objectToSend)
      .then((response) => {
        this.setState({
          isCreating: false,
        });
        if (!this.props.createAnother) this.props.closeSlidingMenuLayer();
      })
      .catch((err) => {
        this.setState({
          isCreating: false,
        });
      });
  };

  getNetworks = (region) => {
    const project_id = getCurrentProjectID(this.props.projects, region);

    FetchAPI.Networking.Networks.getList({
      region: region.toLowerCase(),
      project_id,
    })
      .then((res) => {
        if (res) {
          let mappedNetwork = {};
          res.data.forEach((e) => {
            mappedNetwork[e.id] = e.name;
          });
          let networks = res.data.map((item) => ({
            key: item.id,
            value: item.id,
            text: item.name,
            className: getSelectItemClassName(item.name),
          }));

          this.setState({ networks });
        }
      })
      .catch((err) => {
        toastError(err, "Networks list load failed!");
      });
  };

  componentDidMount() {
    const areasList = renderZonesForSelectBox(
      this.props.projects,
      this.props.userDomains,
    );

    if (this.props.predefined_params) {
      if (this.props.predefined_params.region) {
        this.updateform(
          "zone",
          areasList.find(
            (area) =>
              area.value.toLowerCase() ===
              this.props.predefined_params.region.toLowerCase(),
          ),
        );
      }
      this.setState({
        ...this.props.predefined_params,
        areasList,
      });
    }
  }

  check_required_fields = () => {
    let returnValue = null;
    if (!this.state.name) {
      returnValue = {
        text: "Please provide a name for your Subnet",
        ref: "nameRef",
      };
    } else if (!this.state.zone) {
      returnValue = {
        text: "Please choose a Region",
        ref: "zoneRef",
      };
    } else if (!this.state.network_id) {
      returnValue = {
        text: "Please choose a network",
        ref: "networkRef",
      };
    } else if (
      this.state.ipv6SubnetSettingsFormChecker &&
      this.state.ip_version === "6"
    ) {
      returnValue = this.state.ipv6SubnetSettingsFormChecker();
    }

    if (returnValue && this.state.shake === true) {
      const element = this[returnValue?.ref]?.firstElementChild;
      if (element && element.tagName?.toLowerCase() === "input") {
        element.focus();
      }
      setTimeout(() => {
        this.setState({ shake: false });
      }, 1000);
    }
    return returnValue;
  };

  setFormChecker = (formChecker) => {
    this.setState({ ipv6SubnetSettingsFormChecker: formChecker });
  };

  render() {
    const ip_option = defaultValues.subnet.ip_version.map((x, i) => ({
      key: String(i),
      value: x,
      text: `IP Ver: ${x}`,
      className: getSelectItemClassName(`IP Ver: ${x}`),
    }));

    const { invalidForm } = this.state;
    const form_status = this.check_required_fields();

    const areasList = this.state.areasList
      ? this.state.areasList
      : renderZonesForSelectBox(this.props.projects, this.props.userDomains);

    return (
      <div className={`creator-component-wrapper`}>
        <div className="">
          <FancyHeader title="Create a Subnet" knowledgeBase />

          <p></p>

          <Grid>
            <Grid.Row className="separator padding-top-30">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>Name</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Ref innerRef={(x) => (this.nameRef = x)}>
                  <Input
                    value={this.state.name}
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "nameRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    onChange={(e) =>
                      this.updateform("name", e.currentTarget.value)
                    }
                  />
                </Ref>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <h5>Region</h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <Ref innerRef={(x) => (this.zoneRef = x)}>
                  <Select
                    disabled={!!this.props.predefined_params}
                    icon="chevron circle down"
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "zoneRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    placeholder={
                      this.state.zone
                        ? getFullRegionName(
                            this.props.domains,
                            this.state.zone.value,
                          )
                        : "Choose Region"
                    }
                    options={areasList}
                    onChange={(e, d) => {
                      this.updateform(
                        "zone",
                        areasList.find((area) => area.value === d.value),
                      );
                    }}
                  />
                </Ref>
              </Grid.Column>
            </Grid.Row>
            {(this.state.zone || this.props.predefined_params) && (
              <>
                <Grid.Row className="separator padding-top-30">
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter"
                  >
                    <h5>IP Version</h5>
                  </Grid.Column>
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="flex vcenter"
                  >
                    <Select
                      icon="chevron circle down"
                      className="select-box full"
                      placeholder="Choose IP version"
                      defaultValue="4"
                      options={ip_option}
                      onChange={(e, { value }) =>
                        this.updateform("ip_version", value)
                      }
                    />
                  </Grid.Column>

                  {this.state.ip_version === "4" ? (
                    <>
                      <Grid.Column
                        textAlign="left"
                        width={8}
                        className="flex vcenter margin-top-30"
                      >
                        <h5>
                          CIDR
                          <Popup
                            trigger={
                              <Icon
                                name="warning circle"
                                color="grey"
                                size="small"
                                className="margin-left-10"
                              />
                            }
                          >
                            A valid value will look like
                            10.Current_Region_ID.0.0/24. If no value provided,
                            the default will be set for you
                          </Popup>
                        </h5>
                      </Grid.Column>
                      <Grid.Column
                        textAlign="left"
                        width={8}
                        className="flex vcenter margin-top-30"
                      >
                        <Input
                          value={this.state.cidr}
                          className="select-box full"
                          onChange={(e) =>
                            this.updateform("cidr", e.currentTarget.value)
                          }
                        />
                      </Grid.Column>
                    </>
                  ) : (
                    <IPv6SubnetSettings
                      region={this.state.zone.value}
                      projectId={getCurrentProjectID(
                        this.props.projects,
                        this.state.zone.value,
                      )}
                      onUpdate={this.updateIPv6SubnetSettings}
                      wrapperClassName="flex vcenter margin-top-30"
                      setFormChecker={this.setFormChecker}
                    />
                  )}
                </Grid.Row>
                <Networks
                  title="Network"
                  loading={!this.state.networks}
                  networks={this.state.networks}
                  update={this.updateNetwork}
                  className={get_FormItem_ClassName(
                    form_status,
                    invalidForm,
                    "networkRef",
                    this.state.shake,
                    "error-form-item",
                  )}
                  wrapperClassName="separator padding-top-30"
                />
              </>
            )}

            <Grid.Row>
              <Grid.Column textAlign="left" width={16}>
                {!form_status ? (
                  this.state.isCreating ? (
                    <button className="float-right button button--green overflow-hidden button--icon__right">
                      <Icon loading name="spinner" />
                      <span>Creating</span>
                    </button>
                  ) : (
                    <button
                      className="float-right button button--green"
                      onClick={() => this.createSubnet()}
                    >
                      <span>Create</span>
                    </button>
                  )
                ) : (
                  <Popup
                    trigger={
                      <button
                        className="float-right button button--green button--disabled button--icon__left"
                        onClick={() => {
                          this.setState({ invalidForm: true, shake: true });
                          handleScrollToItem(this[form_status.ref]);
                        }}
                      >
                        <Icon name="exclamation circle" />
                        <span>Create</span>
                      </button>
                    }
                  >
                    {form_status?.text}
                  </Popup>
                )}

                <Checkbox
                  className="simple-checkbox float-right margin-top-half"
                  label="Create Another "
                  checked={this.props.createAnother}
                  onChange={this.props.changeCreateAnother}
                />
                <button
                  className="button button--bordered"
                  onClick={() => this.props.closeSlidingMenuLayer()}
                >
                  <span>Back</span>
                </button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    domains: state.domains.list,
    projects: state.projects,
    userDomains: state.usersettings?.selectedDomains || null,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createSubnet: (rgn, pid, obj) => dispatch(createSubnet(rgn, pid, obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubnetCreator);
