import React from "react";
import ImageSelect from "../../../components/shared/image-select/ImageSelect";
import FancyHeader from "../../../components/shared/FancyHeader";
import { connect } from "react-redux";

import {
  Select,
  Grid,
  Input,
  Checkbox,
  Loader,
  Icon,
  Popup,
  Ref,
} from "semantic-ui-react";
import { createClusterTemplate } from "./actions";
import {
  renderZonesForSelectBox,
  getCurrentProjectID,
  handleScrollToItem,
  get_FormItem_ClassName,
  toastError,
  convertArrayToDropDownList,
} from "../../../app_shared_functions";
import { getSelectItemClassName } from "../../../shared-functions/string";

import Flavors from "../../servers/flavors/Flavors";
import Networks from "../../../components/shared/resources-list/Networks";
import FetchAPI from "../../../api/FetchAPI";

class ClusterTemplateCreator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreating: false,
      zone: "",
      name: "",
      masterFlavorStatus: true,
    };
  }

  updateMasterFlavors = ({ ram, core, volume }) => {
    this.setState({
      ram,
      cores: core,
      volume,
    });
    if (this.state.masterFlavorStatus) {
      this.updateFlavors({ ram, core, volume });
    }
  };

  updateFlavors = ({ ram, core, volume }) => {
    this.setState({
      ram2: ram,
      cores2: core,
      volume2: volume,
    });
  };

  componentDidMount() {
    this.setState({
      areasList: renderZonesForSelectBox(
        this.props.projects,
        this.props.userDomains,
      ),
    });
  }

  updateform = (name, data) => {
    if (
      name === "zone" &&
      (!this.state.zone || this.state.zone.value !== data.value)
    ) {
      this.setState({
        networkList: null,
        selectedNetwork: null,
        images: null,
        selectedImage: null,
        keypairsList: null,
        keypairStatus: false,
        [name]: data,
        invalidForm: false,
      });
      this.getAvailableImagesList(data.value);
    } else if (name === "keypair") {
      this.setState({ [name]: data.value, invalidForm: false });
    } else if (name === "docker_volume_size") {
      this.setState({
        [name]: data.replace(/[^0-9]/g, ""),
        invalidForm: false,
      });
    } else {
      this.setState({ [name]: data, invalidForm: false });
    }
  };

  createCT = () => {
    const region = this.state.zone.value.toLowerCase();
    const objectToSend = {
      name: this.state.name,
      image_id: this.state.selectedImage.id,
      external_network_id: this.state.selectedNetwork,
      cores: this.state.cores || 1,
      ram: this.state.ram_val || this.state.ram || 1,
      disk: this.state.volume_val || this.state.volume || 20,
    };
    if (!this.state.masterFlavorStatus) {
      objectToSend.cores2 = this.state.cores2;
      objectToSend.ram2 = this.state.ram2;
      objectToSend.disk2 = this.state.volume2;
    }

    if (this.state.keypairStatus && this.state.keypair)
      objectToSend.keypair_id = this.state.keypair;
    if (this.state.docker_volume_size)
      objectToSend.docker_volume_size = this.state.docker_volume_size;
    if (this.state.master_lb === false) objectToSend.master_lb = false;
    if (this.state.floating_ip === false) objectToSend.floating_ip = false;
    if (this.state.dns_nameserver)
      objectToSend.dns_nameserver = this.state.dns_nameserver;

    const currentProjectID = getCurrentProjectID(this.props.projects, region);

    this.setState({ isCreating: true });

    this.props
      .createClusterTemplate(region, currentProjectID, objectToSend)
      .then((response) => {
        this.setState({
          isCreating: false,
        });
        if (!this.props.createAnother) this.props.closeSlidingMenuLayer();
      })
      .catch((err) => {
        this.setState({
          isCreating: false,
        });
      });
  };

  handleSelect = (selectedImageId) => {
    const selectedImage = this.state.images.find(
      (os) => os.id === selectedImageId,
    );

    this.setState({
      selectedImage,
      invalidForm: false,
    });
  };

  getAvailableImagesList = (region) => {
    this.setState({ imagesLoading: true });
    const project_id = getCurrentProjectID(this.props.projects, region);

    FetchAPI.Image.getList({
      region: region.toLowerCase(),
      project_id,
      type: "image",
    })
      .then((response) => {
        if (response && response.status === 200) {
          const images = response.data.filter(
            (x) => x.os_distro && x.os_distro !== "windows",
          );
          const availableImage = [
            ...new Set(images.map((item) => item.os_distro)),
          ];
          this.setState({
            images,
            availableImage,
            imagesLoading: false,
          });
        }
      })
      .catch((err) => {
        toastError(err, "Images list loading failed!");
      })
      .finally(() => this.getNetworksList(region));
  };

  /* get list of networks */
  getNetworksList = (region) => {
    this.setState({ networksLoading: true });
    const project_id = getCurrentProjectID(this.props.projects, region);

    FetchAPI.Networking.Networks.getList({
      region: region.toLowerCase(),
      project_id,
    })
      .then((response) => {
        if (response && response.status === 200) {
          const validNetworks = response.data.filter(
            (x) => x["router:external"],
          );
          this.setState({
            networkList: validNetworks,
            networksLoading: false,
          });
        }
      })
      .catch((err) => {
        toastError(err, "Networks list load failed!");
      });
  };

  setKeypairStatus = () => {
    this.setState({ keypairStatus: !this.state.keypairStatus }, () => {
      if (this.state.keypairStatus && this.state.zone)
        this.getKeyPairList(this.state.zone.value.toLowerCase());
    });
  };

  getKeyPairList = (region) => {
    const project_id = getCurrentProjectID(this.props.projects, region);
    this.setState({ keypairLoading: true });

    FetchAPI.Compute.KeyPairs.getList({
      region: region.toLowerCase(),
      project_id,
    })
      .then((response) => {
        if (response && response.status === 200) {
          this.setState({
            keypairsList: response.data, //list of searched snapshots, this will be changed by user search
            keypairLoading: false,
          });
        }
      })
      .catch((err) => {
        toastError(err, "Keypairs list load failed");
      });
  };

  check_required_fields = () => {
    let returnValue = null;
    if (!this.state.name) {
      returnValue = {
        text: "Please provide a name for your Template",
        ref: "nameRef",
      };
    } else if (!this.state.zone) {
      returnValue = {
        text: "Please choose a Region",
        ref: "zoneRef",
      };
    } else if (!this.state.selectedImage) {
      returnValue = {
        text: "Please choose an Image",
        ref: "imageRef",
      };
    } else if (!this.state.selectedNetwork) {
      returnValue = {
        text: "Please choose a Network",
        ref: "networkRef",
      };
    }
    if (returnValue && this.state.shake === true) {
      const element = this[returnValue?.ref]?.firstElementChild;
      if (element && element.tagName?.toLowerCase() === "input") {
        element.focus();
      }
      setTimeout(() => {
        this.setState({ shake: false });
      }, 1000);
    }
    return returnValue;
  };

  render() {
    const {
      networkList,
      networksLoading,
      images,
      imagesLoading,
      selectedImage,

      keypairsList,
      keypairStatus,
      keypairLoading,
      areasList,
      invalidForm,
    } = this.state;

    const form_status = this.check_required_fields();

    return (
      <div className={`creator-component-wrapper`}>
        <div className="">
          <FancyHeader title="Create a Cluster Template" knowledgeBase />
          <p></p>
          <Grid>
            {/* NAME ZONE */}
            <Grid.Row className="separator padding-top-30">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>Name</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Ref innerRef={(x) => (this.nameRef = x)}>
                  <Input
                    value={this.state.name}
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "nameRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    onChange={(e) =>
                      this.updateform("name", e.currentTarget.value)
                    }
                  />
                </Ref>
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={8}
                className="margin-top-30 flex vcenter"
              >
                <h5>Region</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8} className="margin-top-30">
                {areasList && (
                  <Ref innerRef={(x) => (this.zoneRef = x)}>
                    <Select
                      icon="chevron circle down"
                      className={get_FormItem_ClassName(
                        form_status,
                        invalidForm,
                        "zoneRef",
                        this.state.shake,
                        "error-form-item",
                      )}
                      placeholder="Choose Region"
                      options={areasList}
                      onChange={(e, d) => {
                        this.updateform(
                          "zone",
                          areasList.find((area) => area.value === d.value),
                        );
                      }}
                    />
                  </Ref>
                )}
              </Grid.Column>
            </Grid.Row>

            {/* MASTER FLAVOR */}
            <Grid.Row className="separator padding-top-30">
              <Grid.Column textAlign="left" width={16}>
                <h5 className="margin-bottom-20">
                  Master flavor
                  <Popup
                    content="The nova flavor specified to the master node for this baymodel/cluster template."
                    trigger={
                      <Icon
                        name="warning circle"
                        color="grey"
                        className="margin-left-10"
                      />
                    }
                  />
                </h5>
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={16}
                className="margin-top-30"
              >
                <Flavors
                  minRam={1}
                  defaultRam={this?.state?.ram || 1}
                  defaultVolume={this?.state?.volume}
                  defaultCores={this?.state?.cores}
                  updateFlavors={this.updateMasterFlavors}
                />
              </Grid.Column>

              <Grid.Column
                textAlign="left"
                width={16}
                className="padding-top-20"
              >
                <Checkbox
                  toggle
                  checked={this.state.masterFlavorStatus}
                  label="Use the same flavor for all nodes"
                  onChange={() =>
                    this.setState({
                      masterFlavorStatus: !this.state.masterFlavorStatus,
                    })
                  }
                />
              </Grid.Column>

              {/* FLAVOR */}
              {!this.state.masterFlavorStatus && (
                <React.Fragment>
                  <Grid.Column textAlign="left" width={16}>
                    <h5 className="margin-top-30 margin-bottom-20">
                      Node flavor
                      <Popup
                        content="The nova flavor for booting the node servers."
                        trigger={
                          <Icon
                            name="warning circle"
                            color="grey"
                            className="margin-left-10"
                          />
                        }
                      />
                    </h5>
                  </Grid.Column>

                  <Grid.Column
                    textAlign="left"
                    width={16}
                    className="margin-top-30"
                  >
                    <Flavors
                      minRam={1}
                      defaultRam={this?.state?.ram2 || 1}
                      defaultVolume={this?.state?.volume2}
                      defaultCores={this?.state?.cores2}
                      updateFlavors={this.updateFlavors}
                    />
                  </Grid.Column>
                </React.Fragment>
              )}
            </Grid.Row>

            {/* IMAGES */}
            {(imagesLoading || (images !== null && images !== undefined)) && (
              <Grid.Row className="separator padding-top-30">
                <Grid.Column
                  textAlign="left"
                  width={8}
                  className="flex vcenter"
                >
                  <h5 ref={(x) => (this.imageRef = x)}>Image</h5>
                </Grid.Column>
                <Grid.Column textAlign="left" width={8}>
                  {imagesLoading ? (
                    <div className="loader-wrapper">
                      <Loader size="mini" active className="one-liner ">
                        Fetching images list...
                      </Loader>
                    </div>
                  ) : selectedImage ? (
                    <button
                      className="button button--bordered width-100p"
                      onClick={() => this.setState({ selectedImage: null })}
                    >
                      <span title={selectedImage.name}>
                        {selectedImage.name}
                      </span>
                      <Icon name="times circle" />
                    </button>
                  ) : images.length > 0 ? (
                    <ImageSelect
                      className={get_FormItem_ClassName(
                        form_status,
                        invalidForm,
                        "imageRef",
                        this.state.shake,
                        "error-form-item",
                      )}
                      parentItems={this.state.availableImage}
                      childItems={this.state.images}
                      searchServer={this.searchServer}
                      handleSelect={this.handleSelect}
                    />
                  ) : (
                    <Input
                      value="No Images found in current Region"
                      className={get_FormItem_ClassName(
                        form_status,
                        invalidForm,
                        "imageRef",
                        this.state.shake,
                        "error-form-item",
                      )}
                      disabled={true}
                    />
                  )}
                </Grid.Column>
              </Grid.Row>
            )}

            {/* NETWORKS */}
            {(networksLoading ||
              (networkList !== null && networkList !== undefined)) && (
              <Networks
                title="Network"
                loading={networksLoading}
                networks={convertArrayToDropDownList(networkList)}
                update={(id) => this.updateform("selectedNetwork", id)}
                className={get_FormItem_ClassName(
                  form_status,
                  invalidForm,
                  "networkRef",
                  this.state.shake,
                  "error-form-item",
                )}
                wrapperClassName="separator padding-top-30"
              />
            )}

            {/* ADVANCED OPTIONS */}
            <Grid.Row className="separator">
              <Grid.Column textAlign="left" width={16}>
                <h4
                  className="cursor_pointer padding-top-10 margin-bottom-10 flex"
                  onClick={() =>
                    this.setState({ advanced: !this.state.advanced })
                  }
                >
                  {this.state.advanced ? (
                    <Icon
                      className="advanced_icon"
                      name="chevron circle down"
                    ></Icon>
                  ) : (
                    <Icon
                      className="advanced_icon"
                      name="chevron circle right"
                    ></Icon>
                  )}
                  <span>Advanced Options</span>
                </h4>
              </Grid.Column>

              {this.state.advanced && (
                <React.Fragment>
                  {/* KEYPAIRS */}
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className=" margin-top-20"
                  >
                    <h5>Keypair</h5>
                    {!this.state.zone.value ? (
                      "Please choose region first"
                    ) : (
                      <Checkbox
                        toggle
                        className="default-height"
                        checked={keypairStatus}
                        label="Include a keypair"
                        onChange={this.setKeypairStatus}
                      />
                    )}
                  </Grid.Column>
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className=" margin-top-20"
                  >
                    <h5>&nbsp;</h5>
                    {this.state.zone.value &&
                      keypairStatus &&
                      keypairsList &&
                      (keypairLoading ? (
                        <div className="loader-wrapper">
                          <Loader size="mini" active className="one-liner">
                            Fetching Keypairs List...
                          </Loader>
                        </div>
                      ) : keypairsList.length > 0 ? (
                        <Select
                          icon="chevron circle down"
                          className="select-box full"
                          placeholder="Choose KeyPair"
                          options={keypairsList.map((x, id) => ({
                            key: id,
                            text: x.keypair.name,
                            value: x.keypair.name,
                            className: getSelectItemClassName(x.keypair.name),
                          }))}
                          onChange={(e, d) => {
                            this.updateform("keypair", d);
                          }}
                        />
                      ) : (
                        <Input
                          value="No keypairs found in current Region"
                          className={`select-box full`}
                          disabled={true}
                        />
                      ))}
                  </Grid.Column>

                  {/* DOCKER VOLUME SIZE */}
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className=" margin-top-30 flex vcenter"
                  >
                    <h5>Docker Volume Size</h5>
                  </Grid.Column>
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className=" margin-top-30"
                  >
                    <Input
                      value={this.state.docker_volume_size}
                      defaultValue={25}
                      placeholder="25"
                      maxLength="6"
                      className="select-box full"
                      onChange={(e) =>
                        this.updateform(
                          "docker_volume_size",
                          e.currentTarget.value,
                        )
                      }
                    />
                  </Grid.Column>

                  {/* DNS */}
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className=" margin-top-30 flex vcenter"
                  >
                    <h5>DNS NameServer</h5>
                  </Grid.Column>
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className=" margin-top-30"
                  >
                    <Input
                      value={this.state.dns_nameserver}
                      defaultValue="8.8.8.8"
                      placeholder="8.8.8.8"
                      className="select-box full"
                      onChange={(e) =>
                        this.updateform("dns_nameserver", e.currentTarget.value)
                      }
                    />
                  </Grid.Column>

                  {/* MASTER LB */}
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className=" margin-top-30 flex vbottom"
                  >
                    <h5>Master LB</h5>
                  </Grid.Column>
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className=" margin-top-30"
                  >
                    <Checkbox
                      toggle
                      defaultChecked={true}
                      checked={
                        this.state.master_lb === true ||
                        this.state.master_lb === undefined
                      }
                      label={
                        this.state.master_lb === true ||
                        this.state.master_lb === undefined
                          ? "Enabled"
                          : "Disabled"
                      }
                      onChange={() =>
                        this.setState({
                          master_lb:
                            this.state.master_lb === undefined ||
                            this.state.master_lb === true
                              ? false
                              : true,
                        })
                      }
                    />
                  </Grid.Column>

                  {/* FLOATING IP */}
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className=" margin-top-30 flex vbottom"
                  >
                    <h5>Floating IP</h5>
                  </Grid.Column>
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className=" margin-top-30"
                  >
                    <Checkbox
                      toggle
                      defaultChecked={true}
                      checked={
                        this.state.floating_ip === true ||
                        this.state.floating_ip === undefined
                      }
                      label={
                        this.state.floating_ip === true ||
                        this.state.floating_ip === undefined
                          ? "Enabled"
                          : "Disabled"
                      }
                      onChange={() =>
                        this.setState({
                          floating_ip:
                            this.state.floating_ip === undefined ||
                            this.state.floating_ip === true
                              ? false
                              : true,
                        })
                      }
                    />
                  </Grid.Column>
                </React.Fragment>
              )}
            </Grid.Row>

            {/* CREATE BUTTONS */}
            <Grid.Row className=" padding-top-30">
              <Grid.Column textAlign="left" width={16}>
                {!form_status ? (
                  this.state.isCreating ? (
                    <button className="float-right button button--green overflow-hidden button--icon__right ">
                      <Icon loading name="spinner" />
                      <span>Creating</span>
                    </button>
                  ) : (
                    <button
                      className="float-right button button--green"
                      onClick={() => this.createCT()}
                    >
                      <span>Create</span>
                    </button>
                  )
                ) : (
                  <Popup
                    trigger={
                      <button
                        className="float-right button button--green button--disabled button--icon__left"
                        onClick={() => {
                          this.setState({ invalidForm: true, shake: true });
                          handleScrollToItem(this[form_status.ref]);
                        }}
                      >
                        <Icon name="exclamation circle" />
                        <span>Create</span>
                      </button>
                    }
                  >
                    {form_status?.text}
                  </Popup>
                )}
                <Checkbox
                  className="simple-checkbox float-right margin-top-half"
                  label="Create Another "
                  checked={this.props.createAnother}
                  onChange={this.props.changeCreateAnother}
                />
                <button
                  className="button button--bordered"
                  onClick={() => this.props.closeSlidingMenuLayer()}
                >
                  <span>Back</span>
                </button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    domains: state.domains.list,
    projects: state.projects,
    userDomains: state.usersettings?.selectedDomains || null,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createClusterTemplate: (rgn, pid, data) =>
    dispatch(createClusterTemplate(rgn, pid, data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClusterTemplateCreator);
