import React from "react";
import FancyHeader from "../../../components/shared/FancyHeader";

import { connect } from "react-redux";
import { Grid, Input, Checkbox, Icon, Popup, Ref } from "semantic-ui-react";
import { createKeypair } from "./actions";
import {
  handleScrollToItem,
  get_FormItem_ClassName,
  toastMultipleResults,
  getCurrentProjectID,
  toggleArrayItem,
} from "../../../app_shared_functions";

class KeypairDuplicator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreating: false,
      name: props?.predefined_params?.name,
      public_key: props?.predefined_params.public_key,
    };
  }

  check_required_fields = () => {
    let returnValue = null;
    const { selectedRegions } = this.state;
    if (selectedRegions && !selectedRegions.length) {
      returnValue = {
        text: "Please choose at least one region",
        ref: "zoneRef",
      };
    }
    // If the only selected region is the same as source region
    // and the name of the target keypair is the same as the currrent name
    // which happens when all the regions are deselected and name is not changed!
    else if (
      selectedRegions &&
      selectedRegions.length === 1 &&
      this.isSameNameAndRegion(selectedRegions[0].toLowerCase())
    ) {
      returnValue = {
        text: "Please choose at least one region",
        ref: "zoneRef",
      };
    }
    if (returnValue && this.state.shake === true) {
      const element = this[returnValue?.ref]?.firstElementChild;
      if (element && element.tagName?.toLowerCase() === "input") {
        element.focus();
      }
      setTimeout(() => {
        this.setState({ shake: false });
      }, 1000);
    }
    return returnValue;
  };

  updateform = (name, data) => {
    if (name === "selected_area") {
      this.setState({
        selectedRegions: toggleArrayItem(this.state.selectedRegions, data),
        invalidForm: false,
      });
    } else {
      this.setState({ [name]: data, invalidForm: false });
    }
  };

  componentDidMount() {
    const domains = this.props.selectedDomains;

    const regionObjects = domains.flatMap((x) => x.area.regions);

    this.setState({ regionObjects, selectedRegions: [] });
  }

  createKeypair = () => {
    const { dispatch } = this.props;
    let objectToSend = {
      keypair: {
        name: this.state.name,
      },
    };
    if (this.state.public_key) {
      objectToSend["keypair"]["public_key"] = this.state.public_key;
    }

    this.setState({ isCreating: true });

    const promises = this.state.selectedRegions
      .filter((x) => !this.isSameNameAndRegion(x))
      .map((region) => {
        const currentProjectID = getCurrentProjectID(
          this.props.projects,
          region.toLowerCase(),
        );
        return new Promise((resolve, reject) =>
          this.props
            .createKeypair(region.toLowerCase(), currentProjectID, objectToSend)
            .then((response) =>
              resolve({
                status: "resolved",
                id: this.props.predefined_params.id,
              }),
            )
            .catch((err) =>
              resolve({
                status: "rejected",
                id: this.props.predefined_params.id,
                desc: err.response.data.error.message,
              }),
            ),
        );
      });

    Promise.all(promises).then((responses) => {
      toastMultipleResults({
        responses,
        resource_name: "keypair",
        action: "duplicate",
        dispatch,
      });
      if (!this.props.createAnother) this.props.closeSlidingMenuLayer();
    });
  };

  isSameNameAndRegion = (region) => {
    return (
      region.toLowerCase() ===
        this.props.predefined_params.region.toLowerCase() &&
      (this.state.name === this.props.predefined_params.name ||
        this.state.name === "")
    );
  };

  render() {
    const { invalidForm, regionObjects } = this.state;

    const form_status = this.check_required_fields();

    return (
      <div className={`creator-component-wrapper`}>
        <div className="">
          <FancyHeader title="Duplicate Keypair" knowledgeBase />

          <p></p>

          <Grid>
            <Grid.Row>
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>
                  Name
                  <Popup
                    trigger={
                      <Icon
                        name="warning circle"
                        color="grey"
                        size="small"
                        className="margin-left-10"
                      />
                    }
                  >
                    A name to be used for the new keypair. allowed characters
                    are Alphabets,numbers, underscore and dash. [A-Za-z0-9_-]
                  </Popup>
                </h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Ref innerRef={(x) => (this.zoneRef = x)}>
                  <Input
                    placeholder={this.props?.predefined_params?.name}
                    value={this.state.name}
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "nameRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    onChange={(e) =>
                      this.updateform("name", e.currentTarget.value)
                    }
                  />
                </Ref>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row
              className={`separator padding-top-30 ${get_FormItem_ClassName(
                form_status,
                invalidForm,
                "zoneRef",
                this.state.shake,
                "error-form-item",
              )}`}
            >
              <Grid.Column textAlign="left" width={16} className="flex vcenter">
                <h5 ref={(x) => (this.zoneRef = x)}>
                  Please select the regions to copy the keypair to
                </h5>
              </Grid.Column>
              {regionObjects &&
                regionObjects.map((region, key) => {
                  return (
                    <Grid.Column
                      key={key}
                      textAlign="left"
                      width={16}
                      className="flex vcenter margin-top-20"
                    >
                      <Checkbox
                        disabled={this.isSameNameAndRegion(region.region)}
                        onChange={(e, d) =>
                          this.updateform("selected_area", region.region)
                        }
                        checked={
                          this?.state?.selectedRegions?.includes(
                            region.region,
                          ) && !this.isSameNameAndRegion(region.region)
                        }
                        toggle
                        className="display-inlineblock"
                        label={`${region.name} (${region.region})`}
                      />
                    </Grid.Column>
                  );
                })}
            </Grid.Row>

            <Grid.Row>
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>
                  Public Key
                  <Popup
                    trigger={
                      <Icon
                        name="warning circle"
                        color="grey"
                        size="small"
                        className="margin-left-10"
                      />
                    }
                  >
                    The public key to import. If this value is omitted, a new
                    key is generated for you.
                  </Popup>
                </h5>
              </Grid.Column>

              <Grid.Column textAlign="left" width={8}>
                <Input
                  value={this.state.public_key}
                  placeholder={this.props.predefined_params?.public_key}
                  className="select-box full"
                  onChange={(e) =>
                    this.updateform("public_key", e.currentTarget.value)
                  }
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column textAlign="left" width={16}>
                {!form_status ? (
                  this.state.isCreating ? (
                    <button className="float-right button button--green overflow-hidden button--icon__right">
                      <Icon loading name="spinner" />
                      <span>Duplicating</span>
                    </button>
                  ) : (
                    <button
                      className="float-right button button--green "
                      onClick={() => this.createKeypair()}
                    >
                      <span>Duplicate</span>
                    </button>
                  )
                ) : (
                  <Popup
                    trigger={
                      <button
                        className="float-right button button--green button--disabled button--icon__left"
                        onClick={() => {
                          this.setState({ invalidForm: true, shake: true });
                          handleScrollToItem(this[form_status.ref]);
                        }}
                      >
                        <Icon name="exclamation circle" />
                        <span>Duplicate</span>
                      </button>
                    }
                  >
                    {form_status?.text}
                  </Popup>
                )}
                <button
                  className="button button--bordered"
                  onClick={() => this.props.closeSlidingMenuLayer()}
                >
                  <span>Back</span>
                </button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    projects: state.projects,
    keypair_new: state.keypairs.NEW_KEYPAIR,
    selectedDomains: state.usersettings.selectedDomains,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createKeypair: (rgn, pid, obj) => dispatch(createKeypair(rgn, pid, obj)),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(KeypairDuplicator);
