import QuickView from "../QuickView";

type IDNameQuickViewProps = {
  id: string;
  name?: string;
  entityType?: string;
};

const IDNameQuickView = ({ id, name, entityType }: IDNameQuickViewProps) => {
  return (
    <QuickView>
      <QuickView.Trigger>{id}</QuickView.Trigger>
      <QuickView.Content>
        <QuickView.Item>{`${entityType || ""} ID: ${id}`}</QuickView.Item>
        <QuickView.Copy copy={id}>Copy ID</QuickView.Copy>
        {name && <QuickView.Copy copy={name}>Copy Name</QuickView.Copy>}
      </QuickView.Content>
    </QuickView>
  );
};

export default IDNameQuickView;
