import React from "react";
import FancyHeader from "../../../components/shared/FancyHeader";

import { connect } from "react-redux";
import {
  Select,
  Grid,
  Input,
  Checkbox,
  Icon,
  Popup,
  Ref,
  TextArea,
} from "semantic-ui-react";
import { createKeypair } from "./actions";
import {
  renderZonesForSelectBox,
  handleScrollToItem,
  get_FormItem_ClassName,
  getCurrentProjectID,
} from "../../../app_shared_functions";

import NewKeypairModal from "./NewKeyPairModal";
import { toast } from "react-toastify";

class KeypairCreator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreating: false,
      zone: "",
      name: "",
      public_key: "",
    };
  }

  check_required_fields = () => {
    let returnValue = null;
    if (!this.state.name) {
      returnValue = {
        text: "Please provide a name for your Keypair",
        ref: "nameRef",
      };
    } else if (!this.state.zone) {
      returnValue = {
        text: "Please choose a Region",
        ref: "zoneRef",
      };
    }

    if (returnValue && this.state.shake === true) {
      const element = this[returnValue?.ref]?.firstElementChild;
      if (element && element.tagName?.toLowerCase() === "input") {
        element.focus();
      }
      setTimeout(() => {
        this.setState({ shake: false });
      }, 1000);
    }
    return returnValue;
  };

  updateform = (name, data) => {
    this.setState({ [name]: data, invalidForm: false });
  };

  createKeypair = () => {
    let region = this.state.zone.value;
    let objectToSend = {
      keypair: {
        name: this.state.name,
      },
    };

    if (this.state.public_key) {
      objectToSend["keypair"]["public_key"] = this.state.public_key;
    }

    const currentProjectID = getCurrentProjectID(this.props.projects, region);

    this.setState({ isCreating: true });
    this.props
      .createKeypair(region, currentProjectID, objectToSend)
      .then((response) => {
        this.setState({
          isCreating: false,
          newKeypair: response,
        });
        toast.success("Creating keypair started...");

        if (response?.keypair?.private_key === undefined) {
          this.onClose();
        }
      })
      .catch((err) => {
        this.setState({
          isCreating: false,
        });
      });
  };

  onClose = () => {
    this.setState({ newKeypair: null });
    if (!this.props.createAnother) this.props.closeSlidingMenuLayer();
  };

  render() {
    const { projects } = this.props;

    const { invalidForm } = this.state;

    const form_status = this.check_required_fields();

    const areasList = renderZonesForSelectBox(projects, this.props.userDomains);

    return (
      <div className={`creator-component-wrapper`}>
        <div className="">
          <FancyHeader title="Create Keypair" knowledgeBase />

          <p></p>

          <Grid>
            <Grid.Row>
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>
                  Name
                  <Popup
                    trigger={
                      <Icon
                        name="warning circle"
                        color="grey"
                        size="small"
                        className="margin-left-10"
                      />
                    }
                  >
                    A name to be used for the new keypair. allowed characters
                    are Alphabets,numbers, underscore and dash. [A-Za-z0-9_-]
                  </Popup>
                </h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Ref innerRef={(x) => (this.nameRef = x)}>
                  <Input
                    value={this.state.name}
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "nameRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    onChange={(e) =>
                      this.updateform("name", e.currentTarget.value)
                    }
                  />
                </Ref>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="separator padding-top-30">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>Region</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8} className="">
                <Ref innerRef={(x) => (this.zoneRef = x)}>
                  <Select
                    icon="chevron circle down"
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "zoneRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    placeholder="Choose Region"
                    options={areasList}
                    onChange={(e, d) => {
                      this.updateform(
                        "zone",
                        areasList.find((area) => area.value === d.value),
                      );
                    }}
                  />
                </Ref>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-bottom-20"
              >
                <h5>
                  Public Keys
                  <Popup
                    trigger={
                      <Icon
                        name="warning circle"
                        color="grey"
                        size="small"
                        className="margin-left-10"
                      />
                    }
                  >
                    You can insert multiple keys to be included in the keypair.{" "}
                    <br />
                    If no value is provided, one will be generated for you.{" "}
                    <br />
                    <br />
                    Maximum available length : 4000 characters
                  </Popup>
                </h5>
              </Grid.Column>

              <Grid.Column textAlign="left" width={16}>
                <TextArea
                  value={this.state.public_key}
                  rows={6}
                  placeholder="Public keys (optional)"
                  maxLength="4000"
                  className="margin-bottom-half"
                  onChange={(e) =>
                    this.updateform("public_key", e.currentTarget.value)
                  }
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column textAlign="left" width={16}>
                {!form_status ? (
                  this.state.isCreating ? (
                    <button className="float-right button button--green overflow-hidden button--icon__right">
                      <Icon loading name="spinner" />
                      <span>Creating</span>
                    </button>
                  ) : (
                    <button
                      className="float-right button button--green "
                      onClick={() => this.createKeypair()}
                    >
                      <span>Create</span>
                    </button>
                  )
                ) : (
                  <Popup
                    trigger={
                      <button
                        className="float-right button button--green button--disabled button--icon__left"
                        onClick={() => {
                          this.setState({ invalidForm: true, shake: true });
                          handleScrollToItem(this[form_status.ref]);
                        }}
                      >
                        <Icon name="exclamation circle" />
                        <span>Create</span>
                      </button>
                    }
                  >
                    {form_status?.text}
                  </Popup>
                )}
                <Checkbox
                  className="simple-checkbox float-right margin-top-half"
                  label="Create Another "
                  checked={this.props.createAnother}
                  onChange={this.props.changeCreateAnother}
                />
                <button
                  className="button button--bordered"
                  onClick={() => this.props.closeSlidingMenuLayer()}
                >
                  <span>Back</span>
                </button>
              </Grid.Column>
            </Grid.Row>

            {this.state?.newKeypair?.keypair?.private_key && (
              <NewKeypairModal
                data={this.state.newKeypair.keypair}
                onClose={this.onClose}
              />
            )}
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    domains: state.domains.list,
    projects: state.projects,
    keypair_new: state.keypairs.NEW_KEYPAIR,
    userDomains: state.usersettings?.selectedDomains || null,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createKeypair: (rgn, pid, obj) => dispatch(createKeypair(rgn, pid, obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(KeypairCreator);
