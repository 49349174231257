import FetchAPI from "../../api/FetchAPI";
import { toastError } from "../../app_shared_functions";
import { TShoot } from "../types";

export const getAdminKubeConfig = (
  gardenDomain: string,
  shoot: TShoot,
  expirationSeconds: number,
) => {
  return new Promise((resolve, reject) => {
    FetchAPI.Gardener.Shoots.createAdminKubeConfig({
      gardenDomain,
      shoot,
      expirationSeconds,
    })
      .then((res) => resolve(res.data))
      .catch((err) => {
        toastError(
          "Error loading kubeConfig for shoot! Please try again later.",
        );
        reject(err);
      });
  });
};
