import { temporaryTimezone } from "../../../../app_constants";
import {
  Grid,
  Icon,
  Select,
  Popup,
  Checkbox,
  Label,
  Input,
} from "semantic-ui-react";
import useMaintenanceWindow from "./useMaintenanceWindow";
import { generateTimeUnits, renderSelectOptions } from "./helpers";
import { HandleChange, Maintenance } from "./types";

type MaintenanceWindowProps = {
  maintenance: Maintenance;
  handleChange: HandleChange;
};

const MaintenanceWindow = ({
  maintenance,
  handleChange,
}: MaintenanceWindowProps) => {
  const {
    timeWindow: { beginHour, beginMinute, endHour, endMinute },
    validEndHours,
    updateAndValidateTimeWindow,
    updateCheckbox,
  } = useMaintenanceWindow(maintenance, handleChange);

  if (!maintenance) return null;

  return (
    <Grid.Row className="separator">
      <Grid.Column
        textAlign="left"
        width={16}
        className="flex vcenter margin-bottom-30"
      >
        <h5>
          Maintenance Window
          <Popup
            trigger={
              <Icon
                name="warning circle"
                className="margin-left"
                color="grey"
              />
            }
            content={
              <p>
                Specify the time window for when the system can perform
                maintenance. This includes upgrades of Kubernetes and Machine
                images for your worker nodes.
                <ul className="padding-top-half padding-bottom-half padding-left-10">
                  <li>
                    The maximum duration for a maintenance window is 6 hours.
                  </li>
                  <li>
                    The selected time defines when maintenance is allowed to
                    <Label>start</Label>, not necessarily when it will finish.
                  </li>
                  <li>
                    If no maintenance window is set, no automatic updates can be
                    performed.
                  </li>
                </ul>
                We strongly recommend that you specify maintenance window to
                keep your cluster secure, up to date, and running smoothly.
              </p>
            }
          />
        </h5>
      </Grid.Column>

      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter margin-bottom-30"
      >
        <h5>Time</h5>
      </Grid.Column>

      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter margin-bottom-30"
      >
        <div className="ui dropdown select-box flex vcenter width-100p no-hover justify-evenly padding-top-00 padding-bottom-00 ">
          <Select
            name="start-hours"
            className="select-box select-box--compact text-right"
            value={beginHour}
            search
            options={renderSelectOptions(generateTimeUnits(24))}
            onChange={updateAndValidateTimeWindow}
          />
          <span className="padding-bottom-half">:</span>
          <Select
            name="start-minutes"
            className="select-box select-box--compact"
            value={beginMinute}
            search
            options={renderSelectOptions(generateTimeUnits(60))}
            onChange={updateAndValidateTimeWindow}
          />

          <span className="padding-bottom-half"> - </span>

          <Select
            name="end-hours"
            className="select-box select-box--compact text-right"
            value={endHour}
            search
            options={renderSelectOptions(validEndHours)}
            onChange={updateAndValidateTimeWindow}
          />
          <span className="padding-bottom-half">:</span>
          <Select
            name="end-minutes"
            className="select-box select-box--compact"
            value={endMinute}
            search
            options={renderSelectOptions(generateTimeUnits(60))}
            onChange={updateAndValidateTimeWindow}
          />
        </div>
      </Grid.Column>

      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter margin-bottom-30"
      >
        <h5>Time Zone</h5>
      </Grid.Column>
      <Grid.Column
        textAlign="left"
        width={8}
        className="flex vcenter margin-bottom-30 padding-right-00 hend"
      >
        <Input
          name="timezone"
          className="select-box full"
          value={temporaryTimezone}
          disabled
        />
      </Grid.Column>

      <Grid.Column
        textAlign="left"
        width={16}
        className="flex vcenter separator separator--simple "
      >
        <h5>Auto update </h5>
      </Grid.Column>

      <Grid.Column
        textAlign="left"
        width={16}
        className="flex vcenter margin-bottom-20"
      >
        <Checkbox
          toggle
          name="machine-image-version"
          checked={maintenance.machineImageVersion}
          label={"Workers Operating System"}
          onChange={updateCheckbox}
        />
        <Popup
          trigger={
            <Icon name="warning circle" color="grey" className="margin-top" />
          }
          content={
            <p>
              Update the operating system of the workers
              <br />
              (requires rolling update of all workers, ensure proper pod
              disruption budgets to ensure availability of your workload)
              <br />
            </p>
          }
        />
      </Grid.Column>

      <Grid.Column
        textAlign="left"
        width={16}
        className="flex vcenter margin-bottom-20"
      >
        <Checkbox
          toggle
          name="kubernetes-version"
          checked={maintenance.kubernetesVersion}
          label="Kubernetes Patch Version"
          onChange={updateCheckbox}
        />
        <Popup
          trigger={
            <Icon name="warning circle" color="grey" className="margin-top" />
          }
          content={
            <p>
              Update the control plane of the cluster and the worker components
              <br />
              (control plane, most notably the API server, will be briefly
              unavailable during switch-over)
              <br />
            </p>
          }
        />
      </Grid.Column>
    </Grid.Row>
  );
};

export default MaintenanceWindow;
