import { CheckboxProps, Grid, Radio } from "semantic-ui-react";
import { ServerConnectType } from "./type";
import { useCallback } from "react";
import { safeToLowerCase } from "../../../../shared-functions/string";

type ConnectToResourceTypeProps = {
  connectingResourceType: ServerConnectType;
  handleChange: (params: { name: string; value: string }) => void;
};

const ConnectToResourceType = ({
  connectingResourceType,
  handleChange,
}: ConnectToResourceTypeProps) => {
  const onChange = useCallback(
    (_: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
      handleChange({
        name: "connectingResourceType",
        value: safeToLowerCase(data.label),
      });
    },
    [handleChange],
  );

  return (
    <div className={`separator width-100p padding-top padding-bottom`}>
      <Grid>
        <Grid.Row>
          <Grid.Column textAlign="left" width={16}>
            Connect to
          </Grid.Column>
          <Grid.Column textAlign="left" width={8}>
            <Radio
              toggle
              label="Network"
              name="connectType"
              checked={connectingResourceType === "network"}
              onChange={onChange}
            />
          </Grid.Column>
          <Grid.Column textAlign="left" width={8}>
            <Radio
              toggle
              label="Port"
              name="connectType"
              checked={connectingResourceType === "port"}
              onChange={onChange}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default ConnectToResourceType;
