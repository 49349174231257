import React from "react";
import FancyHeader from "../../../components/shared/FancyHeader";
import Invoice from "../../../components/shared/invoice/Invoice";

// Provides the image selection and search
import ImageSelect from "../../../components/shared/image-select/ImageSelect";

import { connect } from "react-redux";
import {
  Select,
  Popup,
  Grid,
  Input,
  Checkbox,
  Icon,
  Loader,
  Table,
  TextArea,
  Ref,
} from "semantic-ui-react";
import { closeSlidingMenuLayer } from "../../../actions/toggleSlidingMenu";
import {
  getFullRegionName,
  renderZonesForSelectBox,
  handleScrollToItem,
  get_FormItem_ClassName,
  getCurrentProjectID,
  toastError,
  capitalize,
} from "../../../app_shared_functions";
import { getSelectItemClassName } from "../../../shared-functions/string";

import FetchAPI from "../../../api/FetchAPI";

import { defaultValues } from "../../../app_constants";
import { createVolume } from "./actions";
import { getQuotaExceedingResources } from "../../../shared-functions/quota";
import AvailabilityZoneFilter from "../../availability-zone/AvailabilityZoneFilter";
import StorageClasses from "./bits/StorageClasses";
import { withTranslation } from "react-i18next";

class VolumeCreator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      availabilityZone: "",
      size: defaultValues.volume.default_size,
      description: "",
      disasterRecovery: true,
      classes: {
        data: null,
        isLoading: true,
        isError: false,
      },
    };
  }

  /**
   * On selecting a new source, if source size is bigger than current size, set the size accordingly
   * @param {*} size  the size of the new selected source
   * @returns {undefined} does not return anything
   */
  onSourceSelect = (size) => {
    if (this.state.size < size) {
      this.setState({ size });
    }
  };

  /**
   * If a source is already selected and user blurs from the Size InputBox,
   * check the source min_disk and Size value via onSourceSelect function
   * @returns {undefined} does not return anything
   */
  onSizeBlur = () => {
    const min_disk =
      this.state?.selectedOS?.min_disk ||
      this.props.volumes.VOLUMES_LIST?.[this?.state?.selectedvolume]?.size ||
      this.state?.selectedVolumeSnapshot?.size ||
      this.state?.selectedsnapshot?.min_disk ||
      this.state?.selectedPrivateImage?.min_disk ||
      0;

    this.onSourceSelect(Number(min_disk));
  };

  // get the list of images available
  getOperatingSystemsList = (region) => {
    const project_id = getCurrentProjectID(this.props.projects, region);

    FetchAPI.Image.getList({
      region: region.toLowerCase(),
      project_id,
      type: "image",
      visibility: "public",
    })
      .then((response) => {
        if (response && response.status === 200) {
          // creating list of visible images (those that are not "unknown")
          const osVisibleImages = response.data.filter(
            (item) => item.os !== "unknown",
          );

          // creating the list of os names [windows,ubunto,freebsd...]
          const availableOS = [
            ...new Set(osVisibleImages.map((item) => item.os)),
          ];

          // Setting up the "Operating System Selecting Menu"

          this.setState({
            operatingSystems: response.data, //list of all os versions wheter unknown or not (for search only)
            availableOS, // filtered list of os, only containing the available ones
            osVisibleImages, //list of all os versions wheter unknown or not (for search only)
          });
        } else {
          toastError("Operating Systems list load failed!");
        }
      })
      .catch((err) => {
        toastError(err, "Operating Systems list load failed!");
      });
  };

  // Search Operating Systems images
  searchServer = (value) => {
    const searchedOSResults = value
      ? this.state.osVisibleImages.filter((os) =>
          os.name.toLowerCase().includes(value.toLowerCase()),
        )
      : "value is empty";

    this.setState({ searchedOSResults, invalidForm: false });
  };

  handleResetOS = () => {
    this.setState({ selectedOS: null, invalidForm: false });
  };

  /* SNAP SHOTS */
  getServerSnapshotsList = (region) => {
    const project_id = getCurrentProjectID(this.props.projects, region);

    FetchAPI.Image.getList({
      region: region.toLowerCase(),
      project_id,
      type: "snapshot",
    })
      .then((response) => {
        if (response && response.status === 200) {
          this.setState({
            snapShots: response.data, //list of snapshots
            searchedSnapshotResults: response.data, //list of searched snapshots, this will be changed by user search
          });
        }
      })
      .catch((err) => {
        toastError(err, "Server Snapshots list load failed!");
      });
  };

  handleSnapshotSelect = (e, selectedsnapshotId) => {
    const selectedsnapshot = this.state.snapShots.find(
      (os) => os.id === selectedsnapshotId.value,
    );

    this.onSourceSelect(selectedsnapshot?.min_disk);

    this.setState({
      selectedsnapshot,
      invalidForm: false,
    });
  };

  handleResetServerSnapshot = () => {
    this.setState({ selectedsnapshot: null, invalidForm: false });
  };

  /* SNAP SHOTS */
  getVolumeSnapshotsList = (region) => {
    const project_id = getCurrentProjectID(this.props.projects, region);

    FetchAPI.BlockStorage.Snapshots.getList({
      region: region.toLowerCase(),
      project_id,
    })
      .then((response) => {
        if (response && response.status === 200) {
          this.setState({
            volumeSnapshots: response.data, //list of volumeSnapshots
            searchedVolumeSnapshotResults: response.data, //list of searched volumeSnapshots, this will be changed by user search
          });
        }
      })
      .catch((err) => {
        toastError(err, "Volume snapshots list load failed!");
      });
  };

  handleVolumeSnapshotSelect = (e, selectedVolumeSnapshotId) => {
    const selectedVolumeSnapshot = this.state.volumeSnapshots.find(
      (os) => os.id === selectedVolumeSnapshotId.value,
    );

    this.onSourceSelect(selectedVolumeSnapshot?.size);

    this.setState({
      selectedVolumeSnapshot,
      invalidForm: false,
    });
  };

  handleResetVolumesSnapshot = () => {
    this.setState({ selectedVolumeSnapshot: null, invalidForm: false });
  };

  /* PRIVATE IMAGES */
  getPrivateImagesList = (region) => {
    const project_id = getCurrentProjectID(this.props.projects, region);

    FetchAPI.Image.getList({
      region: region.toLowerCase(),
      project_id,
      type: "image",
      visibility: "private",
    })
      .then((response) => {
        if (response && response.status === 200) {
          this.setState({
            privateImages: response.data, //list of snapshots
            searchedPrivateImages: response.data, //list of searched snapshots, this will be changed by user search
          });
        }
      })
      .catch((err) => {
        toastError(err, "Private images list load failed!");
      });
  };

  handlePrivateImageSelect = (e, selectedPrivateImageId) => {
    const selectedPrivateImage = this.state.privateImages.find(
      (os) => os.id === selectedPrivateImageId.value,
    );

    this.onSourceSelect(selectedPrivateImage?.min_disk);

    this.setState({
      selectedPrivateImage,
      invalidForm: false,
    });
  };

  handleResetPrivateImages = () => {
    this.setState({ selectedPrivateImage: null, invalidForm: false });
  };

  handleResetVolume = () => {
    this.setState({ selectedvolume: null, invalidForm: false });
  };

  handleAvailabilityZoneChange = (availabilityZone) => {
    this.setState({ availabilityZone: availabilityZone });
  };

  updateform(name, data) {
    if (name === "volumeSource") {
      this.setState({
        selectedVolume: "",
        selectedOS: null,
        selectedVolumesnapshot: null,
        selectedVolumeSnapshot: null,
        selectedsnapshot: null,
        selectedPrivateImage: null,
        selectedvolume: null,
        invalidForm: false,
        [name]: data,
      });
      if (data === "Image" && !this.state.operatingSystems)
        this.getOperatingSystemsList(this.state.zone.value);
      if (
        data === "Volume" &&
        !Object.keys(this.props.volumes.VOLUMES_LIST).length
      )
        this.props.subscribeToVolumes();
      if (data === "Volume Snapshot" && !this.state.volumeSnapshots)
        this.getVolumeSnapshotsList(this.state.zone.value);
      if (data === "Server Snapshot" && !this.state.snapShots)
        this.getServerSnapshotsList(this.state.zone.value);
      if (data === "Private Image" && !this.state.privateImages)
        this.getPrivateImagesList(this.state.zone.value);
    } else if (name === "size") {
      let value = Number(data);
      if (!isNaN(value)) {
        if (value > defaultValues.volume.maximum_size) {
          this.setState({ size: defaultValues.volume.maximum_size });
        } else if (data === "") {
          this.setState({ size: "" });
        } else {
          this.setState({ size: Math.floor(value) });
        }
      }
    } else {
      this.setState({
        [name]: data,
        invalidForm: false,
      });
    }

    if (name === "zone") {
      this.setState({
        volumeSource: defaultValues.volume.sources[0],
        selectedVolume: "",
        selectedOS: null,
        selectedVolumesnapshot: null,
        selectedVolumeSnapshot: null,
        selectedsnapshot: null,
        selectedPrivateImage: null,
        selectedvolume: null,
      });
      this.getRegionQuota(data.value);
      this.getVolumeClasses(data.value);
    }
  }

  getRegionQuota(region) {
    FetchAPI.Usage.getQuota({
      region,
      project_id: getCurrentProjectID(this.props.projects, region),
    }).then((res) => {
      this.setState({ quota: res.data });
    });
  }

  // which snapshot is selected
  handleDropdownSelect = (e, selectedVolumeId, type) => {
    let selectedItem;
    if (type === "volume") {
      selectedItem = Object.keys(this.props.volumes.VOLUMES_LIST).find(
        (os) => os === selectedVolumeId.value,
      );

      this.onSourceSelect(
        this.props.volumes.VOLUMES_LIST?.[selectedItem]?.size,
      );
    }
    this.setState({
      ["selected" + type]: selectedItem,
      invalidForm: false,
    });
  };

  // Creating the volume
  createVolume() {
    const region = this.state.zone.value;
    const currentProjectID = getCurrentProjectID(this.props.projects, region);

    const parameters = {
      volume: {
        name: this.state.name,
        size: this.state.size || defaultValues.volume.default_size,
        description: this.state.description || "",
      },
    };

    if (this.state.selectedClass?.disasterRecoverAvailable) {
      parameters.volume.dr_service_enabled = this.state.disasterRecovery;
    }

    if (this.state.selectedClass.name) {
      parameters.volume.volume_type = this.state.selectedClass.name;
    }

    if (this.state.availabilityZone) {
      parameters.volume.availability_zone = this.state.availabilityZone;
    }

    switch (this.state.volumeSource || this.state.source_type) {
      case "Image":
        parameters.volume.imageRef = this.state?.selectedOS?.id;
        break;

      case "Server Snapshot":
        parameters.volume.imageRef =
          this.state?.selectedsnapshot?.id || this.state.imageRef;
        break;

      case "Private Image":
        parameters.volume.imageRef = this.state?.selectedPrivateImage?.id;
        break;

      case "Volume":
        parameters.volume.source_volid = this.state.selectedvolume;
        break;

      case "Volume Snapshot":
        parameters.volume.snapshot_id = this.state.selectedVolumeSnapshot?.id;
        break;

      default:
        break;
    }

    this.setState({
      isCreating: true,
    });

    this.props
      .createVolume(
        this.state.zone.value.toLowerCase(),
        currentProjectID,
        parameters,
      )
      .then(() => {
        this.setState({
          isCreating: false,
        });
        if (!this.props.createAnother) this.props.closeSlidingMenuLayer();
      })
      .catch(() => {
        this.setState({
          isCreating: false,
        });
      });
  }

  componentDidMount() {
    if (this.props.predefined_params) {
      this.setState({ ...this.props.predefined_params });
      this.getVolumeClasses(this.props.predefined_params.zone.value);
    }
  }

  getVolumeClasses = (region) => {
    const project_id = getCurrentProjectID(this.props.projects, region);
    this.setState((prevState) => ({
      classes: {
        ...prevState.classes,
        data: null,
        isLoading: true,
        isError: null,
      },
      selectedClass: null,
    }));

    FetchAPI.BlockStorage.Volumes.getStorageClasses({ region, project_id })
      .then((res) => {
        this.setState((prevState) => ({
          classes: {
            ...prevState.classes,
            data: res.data,
            isLoading: false,
            isError: null,
          },
          selectedClass:
            res.data.find((storageClass) => storageClass.name === "cbs") ||
            res.data[0],
        }));
      })
      .catch((err) => {
        const error =
          err?.response?.data?.error?.message || "An unknown error occurred.";
        this.setState((prevState) => ({
          classes: {
            ...prevState.classes,
            isLoading: false,
            isError: error,
          },
        }));
        toastError(error);
      });
  };

  updateVolumeClass = (selectedClass) => {
    this.setState({ selectedClass });
  };

  createInvoiceItems = () => {
    const { selectedClass, zone, size, disasterRecovery } = this.state;
    const pricePerHour =
      selectedClass?.price?.regions?.find(
        (region) => region.zoneId === zone.key,
      )?.costPerHour ?? 0;

    const items = [
      {
        name: "Disk",
        count: size,
        unit: "GB",
        price: (pricePerHour * 24 * 30 * size).toFixed(2),
        popup: pricePerHour ? (
          <Popup
            trigger={
              <Icon className="padding-left-half" name="question circle" />
            }
          >
            <Table className="simple-table">
              <Table.Body>
                <Table.Row>
                  <Table.Cell className="allCaps">
                    price per hour ({size}GB):
                  </Table.Cell>
                  <Table.Cell className="allCaps">
                    {(pricePerHour * size).toFixed(4)} {this.state?.currency}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Popup>
        ) : null,
      },
    ];

    if (this.state?.selectedClass?.disasterRecoverAvailable) {
      items.push({
        name: "Disaster Recovery",
        count: disasterRecovery ? `Enabled - ${size}GB` : "Disabled",
        unit: "",
        price: disasterRecovery
          ? (pricePerHour * 24 * 30 * size).toFixed(2)
          : 0,
      });
    }
    return items;
  };

  check_required_fields = () => {
    let returnValue = null;
    if (!this.state.name) {
      returnValue = {
        text: "Please provide a name for your Volume",
        ref: "nameRef",
      };
    } else if (!this.state.zone) {
      returnValue = {
        text: "Please choose a Region",
        ref: "zoneRef",
      };
    } else if (this.state.size === "") {
      returnValue = {
        text: "Please set a size for your volume",
        ref: "sizeRef",
      };
    } else if (
      this.state.volumeSource !== defaultValues.volume.sources[0] &&
      !(
        this.state.selectedOS ||
        this.state.selectedPrivateImage ||
        this.state.selectedvolume ||
        this.state.selectedVolumeSnapshot ||
        this.state.selectedsnapshot
      )
    ) {
      returnValue = {
        text: "Please choose a source based on your selection in the advanced options",
        ref: "imageRef",
      };
    } else if (
      this.state.volumeSource === "Volume" &&
      this.state.selectedvolume &&
      this.props.volumes?.VOLUMES_LIST[this.state.selectedvolume]?.size &&
      this.props.volumes.VOLUMES_LIST[this.state.selectedvolume].size >
        (this.state.size || defaultValues.volume.default_size)
    ) {
      returnValue = {
        text: `Volume size ${
          this.state.size || defaultValues.volume.default_size
        }GB cannot be smaller than original volume size  ${
          this.props.volumes.VOLUMES_LIST[this.state.selectedvolume].size
        }GB`,
        ref: "sizeRef",
      };
    }

    const requested = {
      block_storage: {
        num_volumes: 1,
        volume_gb: this.state?.size || defaultValues.volume.default_size,
      },
    };
    const isExceeding = getQuotaExceedingResources(
      this.state?.quota?.available || {},
      requested,
    );
    if (!returnValue && isExceeding) {
      returnValue = {
        text: isExceeding,
        ref: "sizeRef",
      };
    }

    if (returnValue && this.state.shake === true) {
      const element = this[returnValue?.ref]?.firstElementChild;
      if (element && element.tagName?.toLowerCase() === "input") {
        element.focus();
      }
      setTimeout(() => {
        this.setState({ shake: false });
      }, 1000);
    }
    return returnValue;
  };

  // which os is selected, wheter from search or drop down
  handleSelect = (selectedOSId) => {
    const selectedOS = this.state.operatingSystems.find(
      (os) => os.id === selectedOSId,
    );

    this.onSourceSelect(selectedOS.min_disk);

    this.setState({
      selectedOS,
    });
  };

  render() {
    const { predefined_params, projects, userDomains } = this.props;

    const { volumeSource, invalidForm } = this.state;

    // get list of "active" areas inside the "provisioned" domains
    const areasList = renderZonesForSelectBox(projects, userDomains);

    // Volume Advanced Sources
    const volumeSources = defaultValues.volume.sources.map((x) => ({
      key: x,
      text: x,
      value: x,
      className: getSelectItemClassName(x),
    }));

    const form_status = this.check_required_fields();

    return (
      <div className={`creator-component-wrapper`}>
        <div className="">
          <FancyHeader title="Create a Volume" knowledgeBase />
          {this.state?.zone?.key &&
            (this.state?.storagePrice !== "Error" ? (
              <div className="invoice_wrapper">
                <Invoice
                  currency={this.state?.currency}
                  invoice_Items={this.createInvoiceItems()}
                />
              </div>
            ) : (
              <div className="invoice_wrapper">
                <Invoice error="License prices failed to load!" />
              </div>
            ))}

          <p className={`${this.state?.zone?.key ? "padding-top-50" : ""}`}></p>

          <Grid>
            <Grid.Row className="padding-top-30 separator ">
              <Grid.Column textAlign="left" width={8} className="flex vcenter ">
                <h5>Name</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Ref innerRef={(x) => (this.nameRef = x)}>
                  <Input
                    value={this.state ? this.state.name : ""}
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "nameRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    onChange={(e) =>
                      this.updateform("name", e.currentTarget.value)
                    }
                  />
                </Ref>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <h5>Region</h5>
              </Grid.Column>
              <Grid.Column
                textAlign="left"
                width={8}
                className="flex vcenter margin-top-30"
              >
                <Ref innerRef={(x) => (this.zoneRef = x)}>
                  <Select
                    disabled={predefined_params && predefined_params.zone}
                    icon="chevron circle down"
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "zoneRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    placeholder={
                      predefined_params && predefined_params.zone
                        ? getFullRegionName(
                            this.props.domains,
                            predefined_params.zone.value,
                          )
                        : "Choose Region"
                    }
                    options={areasList}
                    onChange={(e, d) =>
                      this.updateform(
                        "zone",
                        areasList.find((area) => area.value === d.value),
                      )
                    }
                  />
                </Ref>
              </Grid.Column>
            </Grid.Row>
            {this.state.zone && (
              <AvailabilityZoneFilter
                onChange={this.handleAvailabilityZoneChange}
                selectedAvailabiltyZone={this.state.availabilityZone}
                region={capitalize(this.state.zone.value)}
                predefined_params={predefined_params}
              />
            )}
            <Grid.Row
              className={`padding-top separator padding-bottom-30 ${get_FormItem_ClassName(
                form_status,
                invalidForm,
                "sizeRef",
                this.state.shake,
                "error-form-group",
              )}`}
            >
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5 ref={(x) => (this.sizeRef = x)}>
                  Size in GB
                  <Popup
                    trigger={
                      <Icon
                        name="warning circle"
                        size="small"
                        color="grey"
                        className="padding-left-half"
                      />
                    }
                    content={
                      <p>
                        Please enter a value between 1 and{" "}
                        {defaultValues.volume.maximum_size}.
                      </p>
                    }
                  />
                </h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <Input
                  onBlur={this.onSizeBlur}
                  value={this.state.size || predefined_params?.size || ""}
                  className="select-box full"
                  placeholder={defaultValues.volume.default_size}
                  onChange={(e, d) => this.updateform("size", d.value)}
                />
              </Grid.Column>
            </Grid.Row>

            {this.state.zone && (
              <StorageClasses
                classes={this.state.classes}
                selectedClass={this.state.selectedClass}
                updateVolumeClass={this.updateVolumeClass}
              />
            )}
            {this.state.selectedClass && (
              <Grid.Row className="padding-top separator">
                <Grid.Column
                  textAlign="left"
                  width={8}
                  className="flex vbottom"
                >
                  <h5>Disaster Recovery</h5>
                </Grid.Column>
                <Grid.Column textAlign="left" width={8}>
                  {this.state.selectedClass.disasterRecoverAvailable ? (
                    <Checkbox
                      disabled={
                        !this.state.selectedClass.disasterRecoverAvailable
                      }
                      toggle
                      checked={this.state.disasterRecovery}
                      label={
                        this.state.disasterRecovery ? "Enabled" : "Disabled"
                      }
                      onChange={() =>
                        this.setState({
                          disasterRecovery: !this.state.disasterRecovery,
                        })
                      }
                    />
                  ) : (
                    <Checkbox
                      toggle
                      disabled={true}
                      checked={false}
                      label="Not Available"
                    />
                  )}
                </Grid.Column>
                {this.state.selectedClass.disasterRecoverAvailable ===
                  false && (
                  <Grid.Column
                    textAlign="left"
                    width={16}
                    className="margin-top-20 italic"
                  >
                    <p>
                      {this.props.t("volumes.storage_classes.dr_not_supported")}
                    </p>
                  </Grid.Column>
                )}
              </Grid.Row>
            )}
            <Grid.Row className="padding-top-30 separator">
              <Grid.Column textAlign="left" width={16}>
                <h5>Description</h5>
                <div
                  className={get_FormItem_ClassName(
                    form_status,
                    invalidForm,
                    "descriptionRef",
                    this.state.shake,
                    "error-form-item",
                  )}
                >
                  <Ref innerRef={(x) => (this.descriptionRef = x)}>
                    <TextArea
                      value={this.state.description}
                      className="select-box full"
                      onChange={(e) =>
                        this.updateform("description", e.currentTarget.value)
                      }
                    />
                  </Ref>
                </div>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="padding-top-30 separator">
              <Grid.Column textAlign="left" width={16}>
                <h4
                  className="margin-bottom-10 cursor_pointer flex"
                  ref={(x) => (this.imageRef = x)}
                  onClick={() =>
                    this.setState({ advanced: !this.state.advanced })
                  }
                >
                  {this.state.advanced ||
                  (predefined_params && predefined_params.imageRef) ? (
                    <Icon
                      className="advanced_icon"
                      name="chevron circle down"
                    />
                  ) : (
                    <Icon
                      className="advanced_icon"
                      name="chevron circle right"
                    />
                  )}
                  <span>Advanced Options</span>
                </h4>
              </Grid.Column>

              {
                /* ADVANCED OPTIONS */
                (this.state.advanced ||
                  (predefined_params && predefined_params.imageRef)) &&
                  (this.state.zone ? (
                    <React.Fragment>
                      <Grid.Column
                        textAlign="left"
                        width={16}
                        className="margin-top-30 margin-bottom-20"
                      >
                        <h5>Choose source for this volume</h5>
                      </Grid.Column>

                      {/* CHOOSE SOURCE TYPE */}
                      <Grid.Column textAlign="left" width={8}>
                        <Select
                          icon="chevron circle down"
                          className={get_FormItem_ClassName(
                            form_status,
                            invalidForm,
                            "imageRef",
                            this.state.shake,
                            "error-form-item",
                          )}
                          placeholder="Choose Boot Source"
                          disabled={
                            predefined_params && predefined_params.source_type
                          }
                          value={
                            (predefined_params &&
                              predefined_params.source_type) ||
                            volumeSource
                          }
                          defaultValue={volumeSources[0].value}
                          options={volumeSources}
                          onChange={(e, d) =>
                            this.updateform("volumeSource", d.value)
                          }
                        />
                      </Grid.Column>

                      {/* CHOOSE SOURCE */}
                      <Grid.Column
                        textAlign="left"
                        width={8}
                        className="flex vcenter"
                      >
                        {(volumeSource === "No source, Empty Volume" ||
                          volumeSource === undefined) &&
                          !predefined_params?.imageRef && (
                            <button className="button button--bordered width-100p">
                              Empty volume will be created
                            </button>
                          )}

                        {/* SOURCE IS NOT DEFINED BY USER
                                        CREATE AN EMPTY VOLUME OR LET USER SELECT TYPE AND NAME TO CREATE VOLUME FROM   */}
                        {volumeSource &&
                          volumeSource === "Image" &&
                          (!this.state.selectedOS ? ( // no image is selected
                            this.state.availableOS ? ( // os lists is loaded from backend
                              <ImageSelect
                                className={get_FormItem_ClassName(
                                  form_status,
                                  invalidForm,
                                  "imageRef",
                                  this.state.shake,
                                  "error-form-item",
                                )}
                                menu={this.state.osMenu}
                                parentItems={this.state.availableOS}
                                childItems={this.state.operatingSystems}
                                handleSelect={this.handleSelect}
                              />
                            ) : (
                              <Loader size="mini" active>
                                Fetching Operating Systems...
                              </Loader>
                            )
                          ) : (
                            <button
                              className="button button--bordered width-100p"
                              onClick={() => this.handleResetOS()}
                            >
                              <span title={this.state.selectedOS.name}>
                                {this.state.selectedOS.name}
                              </span>
                              <Icon name="times circle" />
                            </button>
                          ))}
                        {volumeSource &&
                          volumeSource === "Volume" &&
                          (!this.state.selectedvolume ? (
                            <Select
                              scrolling
                              placeholder="Search..."
                              clearable
                              search
                              icon="chevron circle down"
                              className={get_FormItem_ClassName(
                                form_status,
                                invalidForm,
                                "imageRef",
                                this.state.shake,
                                "error-form-item",
                              )}
                              options={Object.keys(
                                this.props.volumes.VOLUMES_LIST,
                              )
                                .filter(
                                  (x) =>
                                    this.props.volumes.VOLUMES_LIST[
                                      x
                                    ]?.region?.toLowerCase() ===
                                      this.state?.zone?.value?.toLowerCase() &&
                                    this.state?.zone?.value?.toLowerCase(),
                                )
                                .map((x) => ({
                                  key: this.props.volumes.VOLUMES_LIST[x].id,
                                  text:
                                    this.props.volumes.VOLUMES_LIST[x].name ||
                                    "- No Name -",
                                  value: this.props.volumes.VOLUMES_LIST[x].id,
                                  as: "span",
                                  className: getSelectItemClassName(
                                    this.props.volumes.VOLUMES_LIST[x].name,
                                  ),
                                }))}
                              onChange={(e, d) =>
                                this.handleDropdownSelect(e, d, "volume")
                              }
                            />
                          ) : (
                            <button
                              className="button button--bordered width-100p"
                              onClick={() => this.handleResetVolume()}
                            >
                              <span>
                                {this.props.volumes.VOLUMES_LIST[
                                  this.state.selectedvolume
                                ].name || "- No Name -"}
                              </span>
                              <Icon name="times circle" />
                            </button>
                          ))}
                        {volumeSource &&
                          volumeSource === "Volume Snapshot" &&
                          (!this.state.selectedVolumeSnapshot ? (
                            this.state.volumeSnapshots &&
                            this.state.volumeSnapshots.length ? (
                              // If snapshots are loaded and is full
                              <Select
                                scrolling
                                placeholder="Search..."
                                fluid
                                clearable
                                search
                                icon="chevron circle down"
                                className={get_FormItem_ClassName(
                                  form_status,
                                  invalidForm,
                                  "imageRef",
                                  this.state.shake,
                                  "error-form-item",
                                )}
                                options={this.state.volumeSnapshots.map(
                                  (x) => ({
                                    key: x.id,
                                    text: x.name,
                                    value: x.id,
                                    as: "span",
                                    className: getSelectItemClassName(x.name),
                                  }),
                                )}
                                onChange={(e, d) =>
                                  this.handleVolumeSnapshotSelect(e, d)
                                }
                              />
                            ) : this.state.volumeSnapshots &&
                              !this.state.volumeSnapshots.length ? (
                              "No snapshots found!"
                            ) : (
                              <Loader size="mini" active>
                                Fetching Snapshots...
                              </Loader>
                            )
                          ) : (
                            <button
                              className="button button--bordered width-100p"
                              onClick={() => this.handleResetVolumesSnapshot()}
                            >
                              <span>
                                {this.state.selectedVolumeSnapshot.name}
                              </span>
                              <Icon name="times circle" />
                            </button>
                          ))}
                        {volumeSource &&
                          volumeSource === "Server Snapshot" &&
                          (!this.state.selectedsnapshot ? (
                            this.state.snapShots &&
                            this.state.snapShots.length ? (
                              // If snapshots are loaded and is full
                              <Select
                                scrolling
                                placeholder="Search..."
                                fluid
                                clearable
                                search
                                icon="chevron circle down"
                                className={get_FormItem_ClassName(
                                  form_status,
                                  invalidForm,
                                  "imageRef",
                                  this.state.shake,
                                  "error-form-item",
                                )}
                                options={this.state.snapShots.map((x) => ({
                                  key: x.id,
                                  text: x.name,
                                  value: x.id,
                                  as: "span",
                                  className: getSelectItemClassName(x.name),
                                }))}
                                onChange={(e, d) =>
                                  this.handleSnapshotSelect(e, d)
                                }
                              />
                            ) : this.state.snapShots &&
                              !this.state.snapShots.length ? (
                              "No snapshots found!"
                            ) : (
                              <Loader size="mini" active>
                                Fetching Snapshots...
                              </Loader>
                            )
                          ) : (
                            <button
                              className="button button--bordered width-100p"
                              onClick={() => this.handleResetServerSnapshot()}
                            >
                              <span>{this.state.selectedsnapshot.name}</span>
                              <Icon name="times circle" />
                            </button>
                          ))}
                        {volumeSource &&
                          volumeSource === "Private Image" &&
                          (!this.state.selectedPrivateImage ? (
                            this.state.privateImages &&
                            this.state.privateImages.length ? (
                              // If privateImages are loaded and is full
                              <Select
                                scrolling
                                placeholder="Search..."
                                fluid
                                clearable
                                search
                                icon="chevron circle down"
                                className={get_FormItem_ClassName(
                                  form_status,
                                  invalidForm,
                                  "imageRef",
                                  this.state.shake,
                                  "error-form-item",
                                )}
                                options={this.state.privateImages.map((x) => ({
                                  key: x.id,
                                  text: x.name,
                                  value: x.id,
                                  as: "span",
                                  className: getSelectItemClassName(x.name),
                                }))}
                                onChange={(e, d) =>
                                  this.handlePrivateImageSelect(e, d)
                                }
                              />
                            ) : this.state.privateImages &&
                              !this.state.privateImages.length ? (
                              "No privateImages found!, Please change your zone"
                            ) : (
                              <Loader size="mini" active>
                                Fetching Private Images...
                              </Loader>
                            )
                          ) : (
                            <button
                              className="button button--bordered width-100p"
                              onClick={() => this.handleResetPrivateImages()}
                            >
                              <span>
                                {this.state.selectedPrivateImage.name}
                              </span>
                              <Icon name="times circle" />
                            </button>
                          ))}

                        {
                          /* PREDEFINED SOURCE TYPE AND SOURCE NAME */
                          predefined_params?.source_type ===
                            "Server Snapshot" && (
                            <button
                              className="button button--bordered width-100p"
                              disabled
                            >
                              <span>{predefined_params.source_name}</span>
                            </button>
                          )
                        }
                      </Grid.Column>
                    </React.Fragment>
                  ) : (
                    <div className="animateDown">
                      <Grid.Column
                        textAlign="left"
                        width={16}
                        className="margin-left"
                      >
                        <Icon name="warning circle" color="red" /> Please choose
                        a region first....
                      </Grid.Column>
                    </div>
                  ))
              }
            </Grid.Row>

            <Grid.Row>
              <Grid.Column textAlign="left" width={16}>
                {!form_status ? (
                  this.state.isCreating ? (
                    <button className="float-right button button--green overflow-hidden button--icon__right">
                      <Icon loading name="spinner" />
                      <span>Creating</span>
                    </button>
                  ) : (
                    <button
                      className="float-right button button--green"
                      onClick={() => this.createVolume()}
                    >
                      <span>Create</span>
                    </button>
                  )
                ) : (
                  <Popup
                    trigger={
                      <button
                        className="float-right button button--green button--disabled button--icon__left"
                        onClick={() => {
                          this.setState({ invalidForm: true, shake: true });
                          handleScrollToItem(this[form_status.ref]);
                        }}
                      >
                        <Icon name="exclamation circle" />
                        <span>Create</span>
                      </button>
                    }
                  >
                    {form_status?.text}
                  </Popup>
                )}

                <Checkbox
                  className="simple-checkbox float-right margin-top-half"
                  label="Create Another "
                  checked={this.props.createAnother}
                  onChange={this.props.changeCreateAnother}
                />
                <button
                  className="button button--bordered"
                  onClick={() => this.props.closeSlidingMenuLayer()}
                >
                  <span>Back</span>
                </button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    domains: state.domains.list,
    projects: state.projects,
    volumes: state.volumes,
    userDomains: state.usersettings?.selectedDomains || null,
  };
};

const mapDispatchToProps = (dispatch) => ({
  subscribeToVolumes: () => dispatch({ type: `VOLUMES_LIST_SUBSCRIBE` }), //subscribe to network list
  closeSlidingMenuLayer: () => dispatch(closeSlidingMenuLayer()),

  createVolume: (rgn, pid, obj) => dispatch(createVolume(rgn, pid, obj)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(VolumeCreator));
