import { useRef } from "react";
import SimpleTable from "../../../components/shared/SimpleTable";
import QuickView from "../../../components/shared/quickview/QuickView";
import { Grid, Ref } from "semantic-ui-react";
import { toggleSlidingMenu } from "../../../actions/toggleSlidingMenu";
import { useDispatch, useSelector } from "react-redux";
import useSubscribe from "../../../custom-hooks/useSubscribe";
import LoadError from "../../../components/detailedviewmodal/bits/LoadError";
import Loading from "../../../components/detailedviewmodal/bits/Loading";
const MoreData = ({ subnet }) => {
  const dispatch = useDispatch();

  const domElementRef = useRef();

  const subnets = useSelector((state) => state.subnets);
  useSubscribe("SUBNETS_LIST");

  const projects = useSelector((state) => state.projects);
  const currentProjectName =
    projects.currentProjectName || Object.keys(projects.list)[0];

  const renderAllocationPools = (allocationPools) => {
    return allocationPools.map((item, i) => {
      const uniqueKey = `${item.start}-${item.end}-${i}`; // Ensure unique id

      return [
        <div key={`div-${uniqueKey}`} className="padding-left-half">
          <QuickView>
            <QuickView.Trigger>Start: {item.start}</QuickView.Trigger>
            <QuickView.Content>
              <QuickView.Item>{`Start: ${item.start}`}</QuickView.Item>
              <QuickView.Copy copy={item.start}>Copy</QuickView.Copy>
            </QuickView.Content>
          </QuickView>
        </div>,
        <QuickView key={`quickview-${uniqueKey}`}>
          <QuickView.Trigger>End: {item.end}</QuickView.Trigger>
          <QuickView.Content>
            <QuickView.Item>{`End: ${item.end}`}</QuickView.Item>
            <QuickView.Copy copy={item.end}>Copy</QuickView.Copy>
          </QuickView.Content>
        </QuickView>,
      ];
    });
  };

  if (subnets.SUBNETS_LIST[subnet.id]) {
    subnet = subnets.SUBNETS_LIST[subnet.id];

    return (
      <Ref innerRef={domElementRef}>
        <div
          onClick={(e) => e.stopPropagation()}
          className="more-data more-data--padding"
        >
          <Grid className="columns-3">
            <Grid.Row stackable="true">
              <Grid.Column>
                <SimpleTable
                  className="striped-table"
                  content={[
                    ["Name :", subnet.name],
                    ["Created at :", subnet.created_at],
                    ["Updated at :", subnet.updated_at],
                    [
                      "Enable DHCP :",
                      subnet.enable_dhcp
                        ? `${subnet.enable_dhcp}`
                        : "Not Found",
                    ],
                    [
                      "CIDR :",
                      <QuickView>
                        <QuickView.Trigger>{subnet.cidr}</QuickView.Trigger>
                        <QuickView.Content>
                          <QuickView.Item>{`CIDR: ${subnet.cidr}`}</QuickView.Item>
                          <QuickView.Copy copy={subnet.cidr}>
                            Copy CIDR
                          </QuickView.Copy>
                        </QuickView.Content>
                      </QuickView>,
                    ],
                  ]}
                />
              </Grid.Column>
              <Grid.Column>
                <SimpleTable
                  className="striped-table"
                  content={[
                    [
                      "ID :",
                      <QuickView>
                        <QuickView.Trigger>{subnet.id}</QuickView.Trigger>
                        <QuickView.Content>
                          <QuickView.Item>{`Subnet ID: ${subnet.id}`}</QuickView.Item>
                          <QuickView.Modify
                            onClick={() =>
                              dispatch(
                                toggleSlidingMenu("modify", "Subnet", {
                                  id: subnet.id,
                                }),
                              )
                            }
                          >
                            Modify Subnet
                          </QuickView.Modify>
                          <QuickView.Copy copy={subnet.id}>
                            Copy ID
                          </QuickView.Copy>
                          <QuickView.Copy copy={subnet.name}>
                            Copy Name
                          </QuickView.Copy>
                        </QuickView.Content>
                      </QuickView>,
                    ],
                    [
                      "Project id :",
                      <QuickView>
                        <QuickView.Trigger>
                          {subnet.project_id}
                        </QuickView.Trigger>
                        <QuickView.Content>
                          <QuickView.Item>{`Project ID: ${subnet.project_id}`}</QuickView.Item>
                          <QuickView.Copy copy={subnet.project_id}>
                            Copy Project ID
                          </QuickView.Copy>
                          <QuickView.ViewIn
                            type="Project"
                            resource={{ id: currentProjectName }}
                          />
                        </QuickView.Content>
                      </QuickView>,
                    ],
                    [
                      "Network id :",
                      <QuickView>
                        <QuickView.Trigger>
                          {subnet.network_id}
                        </QuickView.Trigger>
                        <QuickView.Content>
                          <QuickView.Item>{`Network ID: ${subnet.network_id}`}</QuickView.Item>
                          <QuickView.Modify
                            onClick={() =>
                              dispatch(
                                toggleSlidingMenu("modify", "Network", {
                                  id: subnet.network_id,
                                }),
                              )
                            }
                          >
                            Modify Network
                          </QuickView.Modify>
                          <QuickView.Copy copy={subnet.network_id}>
                            Copy ID
                          </QuickView.Copy>
                          <QuickView.ViewIn
                            type="Network"
                            resource={{ id: subnet.network_id }}
                          />
                          <QuickView.Details
                            type="Network"
                            resource={{
                              id: subnet.network_id,
                              region: subnet.region,
                              project_id: subnet.project_id,
                            }}
                          />
                        </QuickView.Content>
                      </QuickView>,
                    ],
                    [
                      "Tenant id :",
                      <QuickView>
                        <QuickView.Trigger>
                          {subnet.tenant_id}
                        </QuickView.Trigger>
                        <QuickView.Content>
                          <QuickView.Item>{`Tenant ID: ${subnet.tenant_id}`}</QuickView.Item>
                          <QuickView.Copy copy={subnet.tenant_id}>
                            Copy ID
                          </QuickView.Copy>
                        </QuickView.Content>
                      </QuickView>,
                    ],
                    [
                      "DNS nameservers :",
                      <div className="padding-top-00 padding-bottom-00 flex flex-wrap">
                        {subnet.dns_nameservers.length
                          ? subnet.dns_nameservers.map((x, i) => (
                              <QuickView key={i}>
                                <QuickView.Trigger>{x}</QuickView.Trigger>
                                <QuickView.Content>
                                  <QuickView.Item>{`CIDR: ${x}`}</QuickView.Item>
                                  <QuickView.Copy copy={x}>
                                    Copy CIDR
                                  </QuickView.Copy>
                                </QuickView.Content>
                              </QuickView>
                            ))
                          : "none"}
                      </div>,
                    ],
                  ]}
                />
              </Grid.Column>
              <Grid.Column>
                <SimpleTable
                  className="striped-table no-background"
                  content={[
                    [
                      "Allocation Pools :",
                      subnet.allocation_pools.length === 0 ? "None" : "",
                    ],
                    ...renderAllocationPools(subnet.allocation_pools),
                  ]}
                />
                <SimpleTable
                  className="striped-table reverse-strip-order "
                  content={[
                    ["IP version :", subnet.ip_version],
                    [
                      "IPV6 ra mode :",
                      subnet.ipv6_ra_mode
                        ? `${subnet.ipv6_ra_mode}`
                        : "Not Found",
                    ],
                  ]}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </Ref>
    );
  }
  if (subnets.SUBNETS_LIST_LOADING_ZONES_LEFT === 0) {
    return (
      <Ref innerRef={domElementRef}>
        <LoadError type="Subnet" />
      </Ref>
    );
  }
  return (
    <Ref innerRef={domElementRef}>
      <Loading type="Subnet" />
    </Ref>
  );
};

export default MoreData;
