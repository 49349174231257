import React from "react";
import FancyHeader from "../../../../components/shared/FancyHeader";

import { connect } from "react-redux";
import {
  Select,
  Grid,
  Input,
  Checkbox,
  Radio,
  Icon,
  Popup,
  Ref,
} from "semantic-ui-react";
import { createPool } from "./actions";
import {
  handleScrollToItem,
  get_FormItem_ClassName,
} from "../../../../app_shared_functions";
import { getSelectItemClassName } from "../../../../shared-functions/string";

import { defaultValues } from "../../../../app_constants";

class PoolCreator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreating: false,
      name: "",
      protocol: defaultValues.pool.protocols[0],
      listener_id: defaultValues.pool.listener_id,
    };
  }

  updateform = (name, data) => {
    this.setState({ [name]: data, invalidForm: false });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.session_persistence !== "APP_COOKIE" &&
      this.state.session_persistence === "APP_COOKIE"
    ) {
      this.cookieRef.focus();
    }
  }

  createPool = () => {
    const objectToSend = {
      pool: {
        lb_algorithm: this.state.algorithm || defaultValues.pool.algorithms[0],
        protocol: this.state.protocol,
        name: this.state.name,
        loadbalancer_id: this.props.predefined_params.id,
      },
    };
    if (this.state.listener_id !== defaultValues.pool.listener_id)
      objectToSend.pool.listener_id = this.state.listener_id;

    if (
      this.state.session_persistence &&
      this.state.session_persistence !== "No Session persistence"
    )
      objectToSend.pool.session_persistence = {
        type: this.state.session_persistence,
      };

    if (
      this.state.session_persistence &&
      this.state.session_persistence === "APP_COOKIE"
    )
      objectToSend.pool.session_persistence.cookie_name =
        this.state.cookie_name;

    this.setState({ isCreating: true });

    this.props
      .createPool(this.props.predefined_params, objectToSend)
      .then(() => {
        this.setState({
          isCreating: false,
        });
        if (!this.props.createAnother) this.props.closeSlidingMenuLayer();
      })
      .catch(() => {
        this.setState({
          isCreating: false,
        });
      });
  };

  check_required_fields = () => {
    let returnValue = null;
    if (!this.state.name) {
      returnValue = {
        text: "Please provide a name for your Pool",
        ref: "nameRef",
      };
    }
    if (
      this.state.session_persistence === "APP_COOKIE" &&
      !this.state.cookie_name
    ) {
      returnValue = {
        text: "Please provide a name for App_Cookie",
        ref: "cookieRef",
      };
    }

    if (returnValue && this.state.shake === true) {
      const element = this[returnValue?.ref]?.firstElementChild;
      if (element && element.tagName?.toLowerCase() === "input") {
        element.focus();
      }
      setTimeout(() => {
        this.setState({ shake: false });
      }, 1000);
    }
    return returnValue;
  };

  render() {
    const { name, invalidForm } = this.state;

    const loadbalancer = { ...this.props.predefined_params };

    const listeners = loadbalancer.listeners
      .map((l) => this.props.listeners.LISTENERS_LIST[l.id])
      .map((l) => ({
        key: l.id,
        text: l.name,
        value: l.id,
        className: getSelectItemClassName(l.name),
      }));

    listeners.unshift({
      key: 0,
      text: defaultValues.pool.listener_id,
      value: defaultValues.pool.listener_id,
      className: getSelectItemClassName(defaultValues.pool.listener_id),
    });

    const form_status = this.check_required_fields();

    return (
      <div className={`creator-component-wrapper`}>
        <div className="">
          <FancyHeader title="Create a Pool" knowledgeBase />
          <p></p>
          <Grid>
            {/* NAME */}
            <Grid.Row className="separator padding-top-30">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>Name</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Ref innerRef={(x) => (this.nameRef = x)}>
                  <Input
                    value={name}
                    className={get_FormItem_ClassName(
                      form_status,
                      invalidForm,
                      "nameRef",
                      this.state.shake,
                      "error-form-item",
                    )}
                    onChange={(e) =>
                      this.updateform("name", e.currentTarget.value)
                    }
                  />
                </Ref>
              </Grid.Column>
            </Grid.Row>

            {/* Algorithm */}
            <Grid.Row className="separator padding-top-30">
              <Grid.Column textAlign="left" width={16} className="">
                <h5>Algorithm</h5>
              </Grid.Column>

              {defaultValues.pool.algorithms.map((param, i) => (
                <Grid.Column textAlign="left" width={8} key={i}>
                  <Radio
                    toggle
                    label={param}
                    name="algorithm"
                    value={param}
                    checked={this.state.algorithm === param || i === 0}
                    onChange={(e, v) => this.updateform("algorithm", v.value)}
                  />
                </Grid.Column>
              ))}
            </Grid.Row>

            {/* protocol */}
            <Grid.Row className="separator padding-top-30">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>Protocol</h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Select
                  icon="chevron circle down"
                  className="select-box full"
                  defaultValue="HTTP"
                  options={defaultValues.pool.protocols.map((p, i) => ({
                    key: i,
                    text: p,
                    value: p,
                    className: getSelectItemClassName(p),
                  }))}
                  onChange={(e, d) => {
                    this.updateform("protocol", d.value);
                  }}
                />
              </Grid.Column>
            </Grid.Row>

            {/* Listener */}
            <Grid.Row className="separator padding-top-30">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>Listener </h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Select
                  icon="chevron circle down"
                  className="select-box full"
                  defaultValue={defaultValues.pool.listener_id}
                  options={listeners}
                  onChange={(e, d) => {
                    this.updateform("listener_id", d.value);
                  }}
                />
              </Grid.Column>
            </Grid.Row>

            {/* Session persistence */}
            <Grid.Row className="separator padding-top-30">
              <Grid.Column textAlign="left" width={8} className="flex vcenter">
                <h5>
                  Session persistence
                  <Popup
                    trigger={
                      <Icon
                        name="warning circle"
                        color="grey"
                        size="small"
                        className="margin-left-10"
                      />
                    }
                  >
                    If APP_COOKE is selected, user should also provide a 'Cookie
                    Name' as well.
                  </Popup>
                </h5>
              </Grid.Column>
              <Grid.Column textAlign="left" width={8}>
                <Select
                  icon="chevron circle down"
                  className="select-box full"
                  defaultValue={defaultValues.pool.session_persistence[0]}
                  options={defaultValues.pool.session_persistence.map(
                    (p, i) => ({
                      key: i,
                      text: p,
                      value: p,
                      className: getSelectItemClassName(p),
                    }),
                  )}
                  onChange={(e, d) => {
                    this.updateform("session_persistence", d.value);
                  }}
                />
              </Grid.Column>

              {this.state.session_persistence === "APP_COOKIE" && (
                <React.Fragment>
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="margin-top-30 flex vcenter"
                  >
                    <h5>
                      Cookie Name
                      <Popup
                        trigger={
                          <Icon
                            name="warning circle"
                            color="grey"
                            size="small"
                            className="margin-left-10"
                          />
                        }
                      >
                        Only applicable to the APP_COOKIE session persistence
                        type
                      </Popup>
                    </h5>
                  </Grid.Column>
                  <Grid.Column
                    textAlign="left"
                    width={8}
                    className="margin-top-30 flex vcenter"
                  >
                    <Ref innerRef={(x) => (this.cookieRef = x)}>
                      <Input
                        value={this.state.cookie_name}
                        className={get_FormItem_ClassName(
                          form_status,
                          invalidForm,
                          "cookieRef",
                          this.state.shake,
                          "error-form-item",
                        )}
                        onChange={(e) =>
                          this.updateform("cookie_name", e.currentTarget.value)
                        }
                      />
                    </Ref>
                  </Grid.Column>
                </React.Fragment>
              )}
            </Grid.Row>

            {/* CREATE BUTTONS */}
            <Grid.Row className=" padding-top-30">
              <Grid.Column textAlign="left" width={16}>
                {!form_status ? (
                  this.state.isCreating ? (
                    <button className="float-right button button--green overflow-hidden button--icon__right ">
                      <Icon loading name="spinner" />
                      <span>Creating</span>
                    </button>
                  ) : (
                    <button
                      className="float-right button button--green"
                      onClick={() => this.createPool()}
                    >
                      <span>Create</span>
                    </button>
                  )
                ) : (
                  <Popup
                    trigger={
                      <button
                        className="float-right button button--green button--disabled button--icon__left"
                        onClick={() => {
                          this.setState({ invalidForm: true, shake: true });
                          handleScrollToItem(this[form_status.ref]);
                        }}
                      >
                        <Icon name="exclamation circle" />
                        <span>Create</span>
                      </button>
                    }
                  >
                    {form_status?.text}
                  </Popup>
                )}
                <Checkbox
                  className="simple-checkbox float-right margin-top-half"
                  label="Create Another "
                  checked={this.props.createAnother}
                  onChange={this.props.changeCreateAnother}
                />
                <button
                  className=""
                  onClick={() => this.props.closeSlidingMenuLayer()}
                >
                  <span>Back</span>
                </button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    listeners: state.listeners,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createPool: (lb, obj) => dispatch(createPool(lb, obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PoolCreator);
