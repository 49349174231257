import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { getGardenerDomain } from "../../reducer/selectors";

import useForm from "../../../custom-hooks/form/useForm";
import useFormWarning from "../../../custom-hooks/form/useFormWarning";

import { deep_Compare, toastError } from "../../../app_shared_functions";

import { Grid, Input } from "semantic-ui-react";
import useIsMountedRef from "../../../custom-hooks/useIsMountedRef";

import FancyHeader from "../../../components/shared/FancyHeader";
import FetchAPI from "../../../api/FetchAPI";

import {
  sortKubeVersions,
  removeOlderVersions,
} from "../../helpers/kubeversions";

import {
  filterUsableMachineTypes,
  reduceMachineImages,
} from "../../helpers/machines";

import SelectKubeVersion from "../bits/SelectKubeVersion";
import MaintenanceWindow from "../bits/Maintenance/MaintenanceWindow";
import SchedulesList from "../bits/Schedules/SchedulesList";

import ExternalNetworks from "../../../components/shared/form/ExternalNetworks";

import { updateShoot } from "../../reducer/actions";
import UpdateButtons from "../../../components/shared/form/UpdateButtons";

const ModifyGardenerShoot = ({
  closeSlidingMenuLayer,
  gardener_shoot_cluster,
}) => {
  const isMountedRef = useIsMountedRef();

  const gardenDomain = useSelector(getGardenerDomain);
  const region = gardener_shoot_cluster.region.toLowerCase();

  const [fetchedData, setFetchedData] = useState();
  const [isUpdating, setIsUpdating] = useState(false);

  const [hibernationError, setHibernationError] = useState();

  const dispatch = useDispatch();

  // the hook responsible for the display the field with error
  const { formWarning, showFormWarning, hideFormWarning } = useFormWarning();

  const buildModifyShootData = (shoot) => {
    const value = {};

    value.region = shoot.region.toLowerCase();
    value.kube = shoot.spec.kubernetes.version;
    value.externalnetwork =
      shoot.spec.provider.infrastructureConfig.floatingPoolName;
    value.name = shoot.metadata.name;
    value.maintenance = {
      machineImageVersion:
        shoot.spec.maintenance.autoUpdate.machineImageVersion,
      kubernetesVersion: shoot.spec.maintenance.autoUpdate.kubernetesVersion,
      begin: shoot.spec.maintenance.timeWindow.begin,
      end: shoot.spec.maintenance.timeWindow.end,
    };

    value.hibernation = [...(shoot.spec.hibernation?.schedules || [])];

    return value;
  };

  // this hook is responsible to hold all the form values and validate them
  const { formData, error, handleChange } = useForm({
    validations: [],
    initialState: buildModifyShootData(gardener_shoot_cluster),
  });

  useEffect(() => {
    hideFormWarning();
  }, [hideFormWarning]);

  // Get Kube Versions and Machine Types (Flavors)
  useEffect(() => {
    FetchAPI.Gardener.CloudProfile.getList(gardenDomain).then((res) => {
      const kubeVersions = removeOlderVersions(
        sortKubeVersions(res?.data?.[0]?.spec?.kubernetes?.versions || []),
        formData.kube,
      );

      const fetchedMachineTypes = filterUsableMachineTypes(
        res?.data?.[0]?.spec?.machineTypes || [],
      );

      const fetchedMachineImages = reduceMachineImages(
        res?.data?.[0]?.spec?.machineImages || [],
      );

      if (isMountedRef.current) {
        setFetchedData({
          kubeVersions: kubeVersions,
          machineTypes: fetchedMachineTypes,
          machineImages: fetchedMachineImages,
        });
      }
    });
  }, [formData.kube, gardenDomain, isMountedRef]);

  const update = () => {
    setIsUpdating(true);

    const convertedMaintenance = [
      ...Object.values(gardener_shoot_cluster.spec?.maintenance),
    ].reduce((acc, v) => ({ ...acc, ...v }), {});

    const schedules = [...formData.hibernation];

    const objectToSend = {
      shoot: {
        ...(formData.kube !== gardener_shoot_cluster.spec.kubernetes.version
          ? {
              kubernetes: {
                version: formData.kube,
              },
            }
          : null),

        ...(!deep_Compare(
          formData.hibernation,
          gardener_shoot_cluster.spec?.hibernation?.schedules,
        )
          ? {
              hibernation: {
                ...(schedules.length === 0 ? {} : { schedules: schedules }),
              },
            }
          : null),

        provider: {},

        ...(!deep_Compare(formData.maintenance, convertedMaintenance)
          ? {
              maintenance: {
                autoUpdate: {
                  kubernetesVersion: formData.maintenance.kubernetesVersion,
                  machineImageVersion: formData.maintenance.machineImageVersion,
                },
                timeWindow: {
                  begin: formData.maintenance.begin,
                  end: formData.maintenance.end,
                },
              },
            }
          : null),
      },
    };

    dispatch(
      updateShoot({ ...gardener_shoot_cluster, gardenDomain }, objectToSend),
    )
      .catch((err) => toastError(err, "Error updating shoot"))
      .finally(() => setIsUpdating(false));
  };

  return (
    <div className={`creator-component-wrapper`}>
      <div className="">
        <FancyHeader title="Modify Gardener Shoot Cluster" />

        <p></p>
        <Grid>
          <Grid.Row className="separator padding-top-30">
            <Grid.Column textAlign="left" width={8} className="flex vcenter ">
              <h5>Name</h5>
            </Grid.Column>
            <Grid.Column textAlign="left" width={8}>
              <Input
                disabled={true}
                value={formData.name || ""}
                name="name"
                className={`select-box full`}
              />
            </Grid.Column>
          </Grid.Row>

          {
            // All is well, bootstrapped and ready for the rest of the form to be filled
            fetchedData?.kubeVersions && (
              <React.Fragment>
                <SelectKubeVersion
                  kube={formData.kube || ""}
                  kubeVersions={fetchedData?.kubeVersions}
                  handleChange={handleChange}
                />

                <ExternalNetworks
                  region={region}
                  handleChange={handleChange}
                  defaultNetwork={formData.externalNetwork}
                />

                <MaintenanceWindow
                  maintenance={formData?.maintenance || null}
                  handleChange={handleChange}
                />

                <SchedulesList
                  hibernation={formData?.hibernation}
                  defaultOpened={true}
                  handleChange={handleChange}
                  formWarning={formWarning}
                  setHibernationError={setHibernationError}
                />
              </React.Fragment>
            )
          }

          <UpdateButtons
            error={error || hibernationError}
            showFormWarning={showFormWarning}
            action={update}
            isUpdating={isUpdating}
            closeSlidingMenuLayer={closeSlidingMenuLayer}
          />
        </Grid>
      </div>
    </div>
  );
};

ModifyGardenerShoot.propTypes = {
  closeSlidingMenuLayer: PropTypes.func.isRequired,
  gardener_shoot_cluster: PropTypes.object.isRequired,
};

export default ModifyGardenerShoot;
