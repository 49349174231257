import QuickView from "../../../../../components/shared/quickview/QuickView";

type FixedIPsProps = {
  fixed_ips?: { ip_address: string }[] | null;
};

const FixedIPs = ({ fixed_ips }: FixedIPsProps) => {
  if (!fixed_ips || fixed_ips.length === 0) return <span>None</span>;

  const className =
    fixed_ips?.length === 1
      ? "padding-top-00"
      : "padding-top-00 flex flex-wrap padding-bottom";

  return (
    <div className={className}>
      {fixed_ips.map((fixed_ip, key) => (
        <QuickView key={key}>
          <QuickView.Trigger>{fixed_ip.ip_address}</QuickView.Trigger>
          <QuickView.Content>
            <QuickView.Item>{`IP Address: ${fixed_ip.ip_address}`}</QuickView.Item>
            <QuickView.Copy copy={fixed_ip.ip_address}>
              Copy IP Address
            </QuickView.Copy>
          </QuickView.Content>
        </QuickView>
      ))}
    </div>
  );
};
export default FixedIPs;
