import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Modal, Header, Icon, Select, Popup } from "semantic-ui-react";
import { copyToClipboard } from "../../app_shared_functions";

import { getAdminKubeConfig } from "../helpers/adminconfig";
import KubeConfigData from "../shoot-bits/KubeConfigData";
import useIsMountedRef from "../../custom-hooks/useIsMountedRef";
import { isCreating } from "../helpers/status";
import { DURATION_OPTIONS } from "../constants";

const KubeConfigModal = ({ shoot, setKubeState, gardenDomain }) => {
  const isMountedRef = useIsMountedRef();

  const [config, setConfig] = useState(null);

  const [duration, setDuration] = useState(3600);
  const onDurationSelection = useCallback((_, props) => {
    setDuration(Number(props.value));
  }, []);

  useEffect(() => {
    setConfig(null);
    getAdminKubeConfig(gardenDomain, shoot, duration)
      .then((res) => {
        if (isMountedRef.current) {
          setConfig(res);
        }
      })
      .catch((err) => {
        if (isMountedRef.current) {
          if (isCreating(shoot)) {
            setConfig("creting");
          } else {
            setConfig("error");
          }
        }
      });
  }, [duration, gardenDomain, isMountedRef, shoot]);

  return (
    <Modal
      onClick={(e) => e.stopPropagation()}
      open={true}
      centered={true}
      onClose={() => setKubeState(false)}
    >
      <Header>
        <Header.Content className="flex vcenter ">
          <div className="sidebar_svg">
            <Icon name="archive" />
          </div>
          <div className="color-black content padding-left-half">
            KubeConfig {shoot.metadata.name ? `for ${shoot.metadata.name}` : ""}
          </div>
        </Header.Content>
      </Header>

      <Modal.Content>
        <div className="flex padding-bottom-30">
          <div className="flex-2">
            <Popup
              trigger={
                <h5 className="padding-top padding-top padding-left">
                  KubeConfig Duration
                  <Icon
                    name="exclamation circle"
                    color="grey"
                    className="padding-left"
                  />
                </h5>
              }
              content={
                <p>
                  The config below will stop working after the selected time
                  period. You can recreate it whenever you want.
                </p>
              }
            />
          </div>
          <div className="flex-1">
            <Select
              className="select-box full"
              icon="chevron circle down"
              value={duration}
              onChange={onDurationSelection}
              options={DURATION_OPTIONS}
            />
          </div>
        </div>

        <KubeConfigData config={config} />
      </Modal.Content>

      <Modal.Actions>
        <button
          className="float-left button button--bordered"
          onClick={() => setKubeState(false)}
        >
          <span>Back</span>
        </button>

        {config && config !== "creting" && config !== "error" && (
          <button
            className="float-right button button--blue button--icon__left"
            onClick={() => {
              setKubeState(false);
              copyToClipboard(config);
            }}
          >
            <Icon name="copy" />
            <span>Copy Config</span>
          </button>
        )}
      </Modal.Actions>
    </Modal>
  );
};

KubeConfigModal.propTypes = {
  shoot: PropTypes.object.isRequired,
  setKubeState: PropTypes.func.isRequired,
  gardenDomain: PropTypes.string.isRequired,
};

export default KubeConfigModal;
