import socket from "../websocket-connection.js";

import { fromEvent, merge } from "rxjs";
import { map, switchMap, filter } from "rxjs/operators";

import { getActiveRegionsWithDomainAndProject } from "../selectors/projects";
import { getGardenerDomain } from "../gardener/reducer/selectors";

const connectWebsocketEvent = (eventName) => {
  return (action$, store) => {
    return action$.pipe(
      filter((action) => action.type === `${eventName}_SUBSCRIBE`), // Replace ofType
      switchMap(() => {
        let activeRegionsList = getActiveRegionsWithDomainAndProject(
          store.value,
        );
        const gardenDomain = getGardenerDomain(store.value);

        if (eventName === "GARDENER_SHOOTS_LIST" && gardenDomain) {
          activeRegionsList = activeRegionsList.map((x) => ({
            ...x,
            gardenDomain,
          }));
        }

        socket.emit(`${eventName}_SUBSCRIBE`, { activeRegionsList });

        return merge(
          fromEvent(socket, `${eventName}_DATA_SUCCESS`),
          fromEvent(socket, `${eventName}_DATA_FIRST_LOAD_COMPLETE`),
          fromEvent(socket, `${eventName}_DATA_FIRST_LOAD_PROGRESS`),
          fromEvent(socket, `${eventName}_DATA_STARTED_LOADING`),
          fromEvent(socket, `${eventName}_DATA_ERROR`),

          action$.pipe(
            filter((action) => action.type === `${eventName}_UNSUBSCRIBE`),
            map(() => {
              socket.emit(`${eventName}_UNSUBSCRIBE`);
              return { type: `${eventName}_UNSUBSCRIBED` };
            }),
          ),

          action$.pipe(
            filter((action) => action.type === "PROJECT_TOGGLE"),
            map(() => {
              const activeRegionsList = getActiveRegionsWithDomainAndProject(
                store.value,
              );
              socket.emit(`${eventName}_UNSUBSCRIBE`);

              if (
                store.value.connectivity.activeSubscriptions?.includes(
                  eventName,
                )
              ) {
                socket.emit(`${eventName}_SUBSCRIBE`, { activeRegionsList });
              }

              return { type: `${eventName}_UNSUBSCRIBED` };
            }),
          ),
        );
      }),
      map((obj) =>
        obj.type ? obj : { type: `${eventName}_DATA_SUCCESS`, payload: obj },
      ),
    );
  };
};

export default connectWebsocketEvent;
