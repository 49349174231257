import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { getGardenerDomain } from "../../reducer/selectors";
import { getAvailableRegions } from "../../../selectors/regions";
import { getDomains } from "../../../selectors/projects";

import useForm from "../../../custom-hooks/form/useForm";
import useFormWarning from "../../../custom-hooks/form/useFormWarning";
import useIsMountedRef from "../../../custom-hooks/useIsMountedRef";

import CreateButtons from "../../../components/shared/form/CreateButtons";

import {
  getCurrentProjectID as filterProjectID,
  getFullRegionName,
  convertRegionsToSelectBox,
  getAllRegionsFromDomains,
  markUnselectedRegions,
  toastError,
} from "../../../app_shared_functions";

import {
  normalizeSelectBoxEvent,
  formFieldWarningClassName,
} from "../../../shared-functions/form";

import { Grid, Input, Ref, Select, Icon, Popup } from "semantic-ui-react";

import FancyHeader from "../../../components/shared/FancyHeader";
import FetchAPI from "../../../api/FetchAPI";

import {
  getCloudProfile,
  getGardenerRegions,
} from "../../helpers/cloudprofile";

import { generateRequestedQuota, mapWorker } from "../../helpers/workergroups";

import SelectKubeVersion from "../bits/SelectKubeVersion";
import MaintenanceWindow from "../bits/Maintenance/MaintenanceWindow";
import WorkersList from "../bits/Workers/WorkersList";
import SchedulesList from "../bits/Schedules/SchedulesList";

import ExtenalNetworks from "../../../components/shared/form/ExternalNetworks";

import { createShoot } from "../../reducer/actions";
import Fallback from "../../../components/slidingpanel/Fallback";
import Loading from "../../../components/slidingpanel/Loading";

import { getQuotaExceedingResources } from "../../../shared-functions/quota";
import useFetchQuota from "../../../custom-hooks/useFetchQuota";
import InternalNetwork from "../bits/InternalNetwork";
import CostEstimation from "../../cost/CostEstimation";
import LoadBalancerProvider from "../bits/LoadBalancerProvider";
import AvailabilityZone from "../bits/AvailabilityZone";
import { useDebounce } from "../../../custom-hooks/useDebounce";

const GardenerShootCreator = ({
  createAnother,
  closeSlidingMenuLayer,
  changeCreateAnother,
}) => {
  const isMountedRef = useIsMountedRef();

  const gardenDomain = useSelector(getGardenerDomain);
  const userDomains = useSelector(
    (state) => state.usersettings?.selectedDomains || null,
  );

  const projects = useSelector((state) => state.projects);
  const domains = useSelector(getDomains);

  const currentRegions = useSelector(getAvailableRegions);

  const [bootstrapping, setBootstrapping] = useState(false);
  const [fetchedData, setFetchedData] = useState();
  const [isCreating, setIsCreating] = useState(false);
  const [currentProjectId, setCurrentProjectId] = useState("");
  const [isReloadingRegion, setIsReloadingRegion] = useState(false);

  const [workerError, setWorkerError] = useState();
  const [hibernationError, setHibernationError] = useState();
  const [regions, setRegions] = useState("loading");
  const [quotaError, setQuoteError] = useState();
  const [isShootNameTaken, setIsShootNameTaken] = useState(false);

  const [loadBalancerProviders, setLoadBalancerProviders] = useState([]);
  const [availabilityZones, setAvailabilityZones] = useState([]);

  const nameRef = useRef();
  const regionRef = useRef();
  const workersListRef = useRef();

  const dispatch = useDispatch();

  const nameRegexPattern = /^([a-z0-9]+([a-z0-9-])+[a-z0-9])*$/;

  // the hook responsible for the display the field with error
  const { formWarning, showFormWarning, hideFormWarning } = useFormWarning();

  // this hook is responsible to hold all the form values and validate them
  const { formData, error, handleChange } = useForm({
    validations: [
      {
        field: "name",
        requiredMessage: "Please enter a name for your shoot.",
        custom: {
          validateFunction: (value) =>
            !isShootNameTaken &&
            value.length <= 15 &&
            nameRegexPattern.test(value),
          errorMessage: isShootNameTaken
            ? "Shoot name is already in use"
            : "Name shall only include lowercase letters [a-z], numbers [0-9], and hyphens [-]. It shall begin and end with an alphanumeric character and can be up to 15 characters long.",
        },
        ref: nameRef,
      },
      {
        field: "region",
        requiredMessage: "Please select a region.",
        ref: regionRef,
      },
    ],
    initialState: {},
  });

  const [shootName, setShootName] = useState("");
  const debouncedShootName = useDebounce(shootName);

  const handleShootNameChange = useCallback(
    (event) => {
      let sanitizedValue = event.target.value
        .toLowerCase() // Ensure lowercase
        .replace(/[^a-z0-9-]/g, ""); // Remove disallowed characters

      setShootName(sanitizedValue);
    },
    [setShootName],
  );

  useEffect(() => {
    if (debouncedShootName === "") return;
    FetchAPI.Gardener.Shoots.verifyShootNameTaken({
      gardenDomain,
      name: debouncedShootName,
    })
      .then((response) => {
        setIsShootNameTaken(response.data.isTaken);
        handleChange({ name: "name", value: debouncedShootName });
      })
      .catch((error) => toastError(error, "Shoot name has invalid format."));
  }, [debouncedShootName, dispatch, gardenDomain, handleChange]);

  const handleRegionChange = useCallback(
    (event) => {
      setIsReloadingRegion(true);
      handleChange(event);
    },
    [handleChange],
  );

  const quota = useFetchQuota(formData?.region);

  const getCurrentProjectID = useCallback(() => {
    return filterProjectID(projects, formData.region);
  }, [projects, formData.region]);

  useEffect(() => {
    const requested = generateRequestedQuota(formData?.workers);
    const isExceeding = getQuotaExceedingResources(
      quota?.available || {},
      requested,
    );
    if (isExceeding) {
      setQuoteError({
        message: isExceeding,
        ref: workersListRef,
      });
    } else {
      setQuoteError(null);
    }
  }, [formData.workers, quota]);

  const tryBootstrapping = useCallback(
    (e) => {
      e?.preventDefault();

      setBootstrapping(true);
      const projectId = getCurrentProjectID();
      setCurrentProjectId(projectId);

      FetchAPI.Gardener.Bootstrap.bootstrapProject({
        gardenDomain,
        region: formData.region,
        project_id: projectId,
      })
        .then(() => setBootstrapping(false)) // Bootstrapping was successful (Should happen only the first time for every region)
        .catch((err) => {
          const code = err?.response?.status;

          if (isMountedRef.current) {
            if (code === 409) {
              // Already bootstrapped before
              setBootstrapping(false);
            } else {
              // Bootstrapping failed
              setBootstrapping("error");
            }
          }
        });
    },
    [formData.region, gardenDomain, getCurrentProjectID, isMountedRef],
  );

  useEffect(() => {
    hideFormWarning();
  }, [hideFormWarning]);

  // Get Kube Versions and Machine Types (Flavors)
  useEffect(() => {
    getCloudProfile(gardenDomain)
      .then((res) => {
        if (isMountedRef.current) {
          setFetchedData(res);
        }
      })
      .catch(() => {
        if (isMountedRef.current) {
          setFetchedData("error");
        }
      });
  }, [gardenDomain, isMountedRef]);

  useEffect(() => {
    if (fetchedData) {
      const gardenerRegions = fetchedData.regions;
      const availableRegions = getGardenerRegions(
        gardenerRegions,
        currentRegions,
      );
      const userRegions = userDomains
        ? getAllRegionsFromDomains(userDomains)
        : availableRegions;

      const mappedRegions = markUnselectedRegions(
        availableRegions,
        userRegions,
      );

      setRegions(convertRegionsToSelectBox(mappedRegions));
      setLoadBalancerProviders(fetchedData?.loadBalancerProviders);
    }
  }, [fetchedData, currentRegions, userDomains]);

  // Bootstrap upon region change
  useEffect(() => {
    if (!formData.region) {
      return;
    }
    setAvailabilityZones(
      fetchedData?.availabilityZones[formData.region.toLowerCase()] || [],
    );
    tryBootstrapping();
  }, [fetchedData?.availabilityZones, formData.region, tryBootstrapping]);

  const create = () => {
    setIsCreating(true);

    const objectToSend = {
      shoot: {
        name: formData.name,
        kubernetes: {
          version: formData.kube,
        },
        ...(formData.hibernation.length
          ? {
              hibernation: {
                schedules: [...formData.hibernation],
              },
            }
          : null),
        provider: {
          infrastructureConfig: {
            ...(formData?.externalnetwork?.length
              ? { floatingPoolName: formData.externalnetwork }
              : null),

            ...(formData?.cidr?.length || formData?.network?.length
              ? {
                  networks: {
                    ...(formData?.network?.length && {
                      id: formData.network,
                      router: {
                        id: formData.router,
                      },
                    }),
                    ...(formData?.cidr?.length && {
                      workers: formData.cidr,
                    }),
                  },
                }
              : null),
          },
          controlPlaneConfig: {
            loadBalancerProvider: formData.loadBalancerProvider,
            zone: formData.availabilityZone,
          },
          workers: formData.workers.map((worker) => mapWorker(worker)),
        },
        maintenance: {
          autoUpdate: {
            kubernetesVersion: formData.maintenance.kubernetesVersion,
            machineImageVersion: formData.maintenance.machineImageVersion,
          },
          timeWindow: {
            begin: formData.maintenance.begin,
            end: formData.maintenance.end,
          },
        },
      },
    };

    dispatch(
      createShoot({
        region: formData.region.toLowerCase(),
        project_id: currentProjectId,
        gardenDomain,
        objectToSend,
      }),
    )
      .then(() => {
        if (!createAnother) {
          setTimeout(() => closeSlidingMenuLayer(), 500);
        }
      })
      .finally(() => setIsCreating(false));
  };

  const detailedNameError = (() => {
    const { name } = formData;
    const errors = [];
    if (name === undefined) {
      return "";
    }
    if (name === "") {
      errors.push("Name shall not be empty.");
    }
    if (name.length > 15) {
      errors.push("Name can be up to 15 characters long.");
    }
    if (name.startsWith("-")) {
      errors.push("Name shall start with an alphanumeric character. [a-z0-9]");
    }
    if (name.endsWith("-")) {
      errors.push("Name shall end with an alphanumeric character. [a-z0-9]");
    }
    if (isShootNameTaken) {
      errors.push("Shoot name is already in use");
    }
    if (!/^[0-9a-z-]{1,15}$/.test(name)) {
      errors.push(
        "Name can include lowercase alphanumeric characters and hyphens. [a-z0-9-]",
      );
    }

    if (errors.length) {
      return errors.map((x) => (
        <p className="color-orange padding-bottom-00">
          <Icon name="circle times" className="margin-right-half" />
          <span className="font-S">{x}</span>
        </p>
      ));
    }
    return "";
  })();

  if (regions === "loading") {
    return <Loading title="Loading regions..." />;
  }

  // Show error if no gardener domain is defined!
  if (!gardenDomain) {
    return (
      <Fallback
        component="Shoot Cluster"
        action="create"
        description="(Gardener Domain not found!)"
      />
    );
  }
  if (!regions || !regions.length) {
    return (
      <Fallback
        component="Shoot Cluster"
        action="create"
        description="(Gardener Regions not found!)"
      />
    );
  }

  return (
    <div className={`creator-component-wrapper`}>
      <div className="">
        <FancyHeader title="Create Gardener Shoot Cluster" />

        {formData.region && formData.workers?.length && (
          <CostEstimation
            regionKey={
              regions?.find((region) => region.value === formData.region)?.key
            }
            workers={formData?.workers.map((worker) => ({
              ...worker,
              machine: { type: worker.machinetype },
            }))}
            region={formData.region.toLowerCase()}
            isReloadingRegion={isReloadingRegion}
            setIsReloadingRegion={setIsReloadingRegion}
          />
        )}

        <p className={`${formData.region ? "padding-top-50" : ""}`}></p>

        <Grid>
          <Grid.Row className="">
            <Grid.Column textAlign="left" width={8} className="flex vcenter ">
              <Popup
                trigger={
                  <h5>
                    Name{" "}
                    <Icon
                      name="exclamation circle"
                      className="grey margin-left"
                      size="small"
                    />
                  </h5>
                }
                content={
                  <p>
                    Name can only include lowercase characters [a-z], numbers
                    [0-9], and hyphens [-]. <br />
                    Only alphanumeric characters can appear at the beginning or
                    the end of the name. <br />
                    Name can be up to 15 characters long.
                  </p>
                }
              />
            </Grid.Column>
            <Grid.Column textAlign="left" width={8}>
              <Ref innerRef={nameRef}>
                <Input
                  value={shootName}
                  name="name"
                  className={`select-box full ${formFieldWarningClassName(
                    formWarning,
                    error?.ref,
                    nameRef,
                  )}`}
                  onChange={handleShootNameChange}
                />
              </Ref>
            </Grid.Column>
          </Grid.Row>

          {detailedNameError.length > 0 && (
            <Grid.Row className="padding-top-00 padding-bottom-20 ">
              <Grid.Column textAlign="left" width={8}></Grid.Column>

              <Grid.Column textAlign="left" width={8}>
                {detailedNameError}
              </Grid.Column>
            </Grid.Row>
          )}

          <Grid.Row className="separator padding-top">
            <Grid.Column textAlign="left" width={8} className="flex vcenter">
              <h5>Region</h5>
            </Grid.Column>
            <Grid.Column textAlign="left" width={8} className="flex vcenter">
              <Ref innerRef={regionRef}>
                <Select
                  disabled={bootstrapping === true}
                  name="region"
                  placeholder="Choose Region"
                  value={formData.region}
                  icon="chevron circle down"
                  className={`select-box full ${formFieldWarningClassName(
                    formWarning,
                    error?.ref,
                    regionRef,
                  )}`}
                  options={regions}
                  onChange={(e, d) =>
                    handleRegionChange(normalizeSelectBoxEvent(e, d))
                  }
                />
              </Ref>
            </Grid.Column>
          </Grid.Row>

          {bootstrapping === true && (
            <Grid.Row>
              <Grid.Column
                textAlign="left"
                width={16}
                className="flex vcenter height-formitem"
              >
                <h5>
                  <Icon name="spinner" loading className="margin-right" />
                  Bootstrapping{" "}
                  {getFullRegionName(domains, formData.region) || ""}....
                </h5>
              </Grid.Column>
            </Grid.Row>
          )}

          {bootstrapping === "error" && (
            <Grid.Row>
              <Grid.Column textAlign="left" width={16} className=" ">
                <h5>
                  <Icon
                    name="exclamation circle"
                    className="color-orange margin-right-half"
                  />
                  Bootstrapping failed for the selected region.
                </h5>
                <p className="padding-left-half padding-top-10">
                  Please contact your administrator or our{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.cleura.com/support/"
                  >
                    Support Team
                  </a>{" "}
                  to help you resolve this issue.
                </p>
                <p className="padding-left-half">
                  Or you can
                  <button
                    onClick={tryBootstrapping}
                    className="button button--inline button--no-transition"
                  >
                    Try again
                  </button>
                  .
                </p>
              </Grid.Column>
            </Grid.Row>
          )}

          {
            // All is well, bootstrapped and ready for the rest of the form to be filled
            !bootstrapping && formData.region && (
              <React.Fragment>
                <SelectKubeVersion
                  kube={formData.kube || ""}
                  kubeVersions={fetchedData?.kubeVersions}
                  handleChange={handleChange}
                />

                <LoadBalancerProvider
                  providers={loadBalancerProviders}
                  selectedProvider={formData.loadBalancerProvider}
                  handleChange={handleChange}
                />

                <AvailabilityZone
                  availabilityZones={availabilityZones}
                  selectedAvailabilityZone={formData.availabilityZone}
                  handleChange={handleChange}
                />

                {formData.kube && (
                  <ExtenalNetworks
                    region={formData.region}
                    handleChange={handleChange}
                  />
                )}

                {formData.kube && (
                  <InternalNetwork
                    region={formData.region}
                    projects={projects}
                    handleChange={handleChange}
                  />
                )}

                {formData.kube && (
                  <Ref innerRef={workersListRef}>
                    <WorkersList
                      workers={formData.workers || []}
                      machineTypes={fetchedData?.machineTypes}
                      machineImages={fetchedData?.machineImages}
                      handleChange={handleChange}
                      setWorkerError={setWorkerError}
                      formWarning={formWarning}
                      availabilityZones={availabilityZones}
                    />
                  </Ref>
                )}
              </React.Fragment>
            )
          }

          {formData.workers && (
            <MaintenanceWindow
              maintenance={formData?.maintenance || null}
              handleChange={handleChange}
            />
          )}

          {formData.maintenance && (
            <SchedulesList
              hibernation={formData?.hibernation || null}
              handleChange={handleChange}
              formWarning={formWarning}
              setHibernationError={setHibernationError}
            />
          )}

          <CreateButtons
            error={error || workerError || hibernationError || quotaError}
            showFormWarning={showFormWarning}
            action={create}
            isCreating={isCreating}
            closeSlidingMenuLayer={closeSlidingMenuLayer}
            createAnother={createAnother}
            changeCreateAnother={changeCreateAnother}
          />
        </Grid>
      </div>
    </div>
  );
};

GardenerShootCreator.propTypes = {
  createAnother: PropTypes.bool.isRequired,
  closeSlidingMenuLayer: PropTypes.func.isRequired,
  changeCreateAnother: PropTypes.func.isRequired,
};

export default GardenerShootCreator;
