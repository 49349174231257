import { defaultValues } from "../../../app_constants";
import {
  getNetworkIpVersion,
  hasOnlyIPv6Subnet,
} from "../../../app_shared_functions";
import { Buffer } from "buffer";
import { safeToLowerCase } from "../../../shared-functions/string";

const getFloatingIP = (data) => {
  if (data.externalIP) {
    if (data.selectedNetworks.length === 1) {
      return { network_id: data.selectedNetworks[0] };
    } else if (data.externalIP && data.selectedNetworks.length > 1) {
      return { network_id: data.externalIP || data.selectedNetworks[0] };
    }
  }
};

const getImageRef = (formData, predefined_params) => {
  if (predefined_params) {
    let bootSource = predefined_params.__type;
    if (["private image", "snapshot"].includes(bootSource)) {
      bootSource = "image";
    }
    return { uuid: predefined_params.id, source_type: bootSource };
  }

  const { type, media } = formData.bootSource;
  if (media) {
    if (["Image", "Snapshot", "Private Image"].includes(type)) {
      return { uuid: media.id, source_type: "image" };
    } else if (type === "Volume") {
      return { uuid: media.id, source_type: "volume" };
    }
  }

  return null;
};

const getCommonParams = (data) => {
  const commonParams = {};

  if (data.selectedClass?.disasterRecoverAvailable && data.disasterRecovery) {
    commonParams.dr_service_enabled = true;
  }

  if (data.configDrive) {
    commonParams.config_drive = true;
  }

  if (data.connectingResourceType === "network") {
    if (data.selectedNetworks?.length) {
      commonParams.networks = data.selectedNetworks.map((network) => ({
        uuid: network,
      }));
    }
  } else if (data.connectingResourceType === "port") {
    if (data.selectedPort) {
      commonParams.networks = [{ port: data.selectedPort }];
    }
  }

  if (data.selectedKeyPair) {
    commonParams.key_name = data.selectedKeyPair;
  }

  if (data?.userData?.length > 0) {
    // Keep local copy of userData to not overwrite the formData, otherwise values will not be replaced when using "create another"
    let replacedUserData = data.userData;
    if (data.credentials.username) {
      replacedUserData = replacedUserData.replace(
        "%username%",
        data.credentials.username,
      );
    } else {
      replacedUserData = replacedUserData.replace("name: %username%", "");
    }

    if (data.credentials.password) {
      replacedUserData = replacedUserData.replace(
        "%password%",
        data.credentials.password,
      );
    } else {
      replacedUserData = replacedUserData.replace("passwd: %password%", "");
    }

    commonParams.user_data = Buffer.from(replacedUserData).toString("base64");
  }

  // That's just weird but we need to send the securitygroups as a list of objects and set the name to the id
  // something like this :
  // "security_groups": [
  //   { "name": "the id of the security group #1" },
  //   { "name": "the id of the security group #2" },....
  // ],
  if (data?.selectedSecurityGroups?.length)
    commonParams.security_groups = data.selectedSecurityGroups.map((sg) => ({
      name: sg.id,
    }));

  if (
    data?.credentials?.password &&
    data?.bootSource?.media?.config?.cryptpass_supported
  ) {
    commonParams.cryptpass = data?.credentials.password;
  }

  const connect_floating_ip = getFloatingIP(data);
  if (connect_floating_ip) {
    commonParams.connect_floating_ip = connect_floating_ip;
  }
  if (data.availabilityZone) {
    commonParams.availability_zone = data.availabilityZone;
  }

  return commonParams;
};

export const ephemeralParams = (formData, predefined_params) => {
  let blockDeviceMappingV2;
  let imageRef;
  if (formData.bootSource.type === "volume") {
    // A Case to boot server from volume
    blockDeviceMappingV2 = [
      {
        boot_index: 0,
        source_type: "volume",
        destination_type: "volume",
        uuid: formData.bootSource.media.id,
      },
    ];
  } else {
    imageRef = getImageRef(formData, predefined_params)["uuid"];
  }
  return {
    server: {
      name: formData.name,
      flavorRef: formData.bootTarget.flavor.id,
      imageRef: imageRef,
      block_device_mapping_v2: blockDeviceMappingV2,
      ...getCommonParams(formData),
    },
  };
};

export const newVolumeParams = (formData, predefined_params) => ({
  server: {
    name: formData.name,
    flavorRef: formData.bootTarget.flavor.id,
    block_device_mapping_v2: [
      {
        ...getImageRef(formData, predefined_params),
        destination_type: "volume",
        boot_index: 0,
        volume_size: Number(formData.bootTarget.volume),
        volume_type:
          safeToLowerCase(formData?.bootSource?.type) === "volume"
            ? undefined
            : formData?.selectedClass?.name,
        tag: "disk1",
        ...(formData.bootTarget.deleteVolume
          ? { delete_on_termination: true }
          : null),
      },
    ],
    ...getCommonParams(formData),
  },
});

export const hasOnlyIPv6NetworksSelected = (selectedNetworks, networks) =>
  selectedNetworks.every((selected) =>
    hasOnlyIPv6Subnet(networks.find((net) => net.id === selected)),
  );

export const hasIPv4NetworksSelected = (selectedNetworks, networks) =>
  selectedNetworks.some(
    (selected) =>
      getNetworkIpVersion(networks.find((net) => net.id === selected)) === 4,
  );

export const generateInitName = () => {
  const d = new Date();

  const months =
    d.getMonth() + 1 > 9 ? d.getMonth() + 1 : "0" + (d.getMonth() + 1);
  const days = d.getDate() > 9 ? d.getDate() : "0" + d.getDate();
  const hours = d.getHours() > 9 ? d.getHours() : "0" + d.getHours();
  const minutes = d.getMinutes() > 9 ? d.getMinutes() : "0" + d.getMinutes();

  return `server_${months}${days} ${hours}${minutes}`;
};

export const generateRequestedQuota = (bootTarget) => ({
  compute: {
    num_servers: 1,
    ram_gb: bootTarget.flavor.ram || 1,
    cores: bootTarget.flavor.cores || 1,
  },
  block_storage: {
    num_volumes:
      bootTarget.target === defaultValues.server.boot_target.ephemeral ? 0 : 1,
    volume_gb:
      bootTarget.target === defaultValues.server.boot_target.ephemeral
        ? 0
        : Number(bootTarget.volume) || 1,
  },
});
